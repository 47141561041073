import highlight from "./highlight"
import checksAndSetsDispatchToDOM from "./checks_dispatch_to_dom"
import Events from "./events"

// the data we passing is in the shape that prometheus returns  
const renderHighlights = async (data, appId) => {
    const dispatchToDomIsSet = checksAndSetsDispatchToDOM()
    const page = window.PDFViewerApplication ? window.PDFViewerApplication.page : 1
    if (dispatchToDomIsSet) {
        // attach event handler to events emitted from pdf.js on each page change
        const events = new Events()
        const renderHighlighsOnPageChange = (e) => {
            const { pageNumber } = e.detail
            highlight.render(data, pageNumber, window.PDFViewerApplication.pdfDocument)
        }
        events.addEvent(document, "pagerendered", renderHighlighsOnPageChange, appId)
        events.addEvent(document, "pagechanging", renderHighlighsOnPageChange, appId)
        highlight.render(data, page, window.PDFViewerApplication.pdfDocument)

    } else {
        // TODO --- eject pdfjs
        console.error("PDFJSViewer instance is not available at the time you try to render the highlights.")
    }
}

export default renderHighlights