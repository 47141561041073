import React from "react"
import Info, { FontSize } from "./Info"

interface IOwnProps {
    size?: FontSize
    value: string
}

type Props = IOwnProps

const YearFounded: React.FC<Props> = (props) => {
    const { size = "medium", value } = props

    return (
        <Info type="founded" size={ size } value={ value } />
    )
}

export default YearFounded
