import { Dispatch } from "redux"
import ServiceHost from "../../../di_service/service"
import descriptor_list from "../../../host/descriptor_list"
import { ConnectorTypes, IDescriptor } from "../../../host/types"
import ensure from "../../../utilities/ensure"
import idGenerator from "../../../utilities/id_generator"
import { AddCard, RemoveAllCards } from "../../app/card/redux/actions"
import factories from "../factories"
import {
    IApp,
    IAppInstallationActions,
    INSTALL_APP,
    REMOVE_ALL_APPS,
    REMOVE_APP,
} from "./types"

export const InstallApp = (app: IApp): IAppInstallationActions => ({
    app,
    type: INSTALL_APP,
})

export const RemoveApp = (id: string): IAppInstallationActions => ({
    id,
    type: REMOVE_APP,
})

export const RemoveAllApps = (): IAppInstallationActions => ({
    type: REMOVE_ALL_APPS,
})

export const getConnectorFromHost = async (descId: string): Promise<ConnectorTypes> => {
    const descriptors = await descriptor_list.getDescriptors()
    const descriptor = ensure<IDescriptor>(descriptors.find((desc) => desc.id === descId))

    return descriptor.createConnector(idGenerator())
}

export const fetchAndProcessCards = async (connector: ConnectorTypes, appId: string) => {
    const pdfService = ServiceHost.getPdfService()
    const text = await pdfService.extractText()
    const cards = await connector.getCards(text)
    const processedCards = cards.map((card) => {
        return new factories[ card.entityType ](card, appId)
    })
    return processedCards
}

export const appInstallation = (descId: string) => {
    return async (dispatch: Dispatch, getState: any) => {
        const appId = idGenerator()
        const connector = await getConnectorFromHost(descId)
        const cards = await fetchAndProcessCards(connector, appId)
        const { document } = getState()

        dispatch(InstallApp({
            cardsCount: cards.length,
            connectorColor: connector.descriptor.color,
            connectorIcon: connector.descriptor.iconSrc,
            connectorId: connector.descriptor.id,
            connectorName: connector.descriptor.name,
            connectorShortDescription: connector.descriptor.shortDescription,
            id: appId,
        }))

        if (descId === "53" && !document.name.match(/Moris Venture/g)) { return }

        cards.forEach((card) => {
            dispatch(AddCard(card))
        })
    }
}

export const resetAll = (docName: string) => {
    return async (dispatch: Dispatch, getState: any) => {
        dispatch(RemoveAllCards())
        const { installed } = getState()

        for (const app of installed) {

            if (app.connectorId === "53" && !docName.match(/Moris Venture/g)) {
                continue
            }

            const connector = await getConnectorFromHost(app.connectorId)
            const cards = await fetchAndProcessCards(connector, app.id)
            cards.forEach((card) => {
                dispatch(AddCard(card))
            })
        }

    }
}
