import {
    IApp,
    IAppInstallationActions,
    IAppsState, INSTALL_APP,
    REMOVE_ALL_APPS,
    REMOVE_APP,
} from "./types"

const initInstalationState: IAppsState = []

export const installationReducer = (
    state = initInstalationState,
    action: IAppInstallationActions,
): IAppsState => {
    switch (action.type) {
        case INSTALL_APP:
            return [ ...state, action.app ]

        case REMOVE_APP:
            return state.filter((app: IApp) => app.id !== action.id)

        case REMOVE_ALL_APPS:
            return []

        default:
            return state
    }
}
