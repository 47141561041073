import normalizeText from "./normalize_text"

const extractText = async () => {
    const text = []
    const app = window.PDFViewerApplication
    const { pagesCount } = app

    for (let i = 1; i <= pagesCount; i++) {
        const page = await app.pdfDocument.getPage(i)
        const textContent = await page.getTextContent()
        text.push(textContent.items.map(i => i.str).join(""))
    }

    return normalizeText(text.join(""))
}

export default extractText