import Address from "./sub_components/Address"
import BankAccount from "./sub_components/BankAccount"
import Ceo from "./sub_components/Ceo"
import Default from "./sub_components/Default"
import Industry from "./sub_components/Industry"
import { FontSize } from "./sub_components/Info"
import Noe from "./sub_components/Noe"
import Phone from "./sub_components/Phone"
import Email from "./sub_components/Phone"
import Sector from "./sub_components/Sector"
import SubIndustry from "./sub_components/SubIndustry"
import Url from "./sub_components/Url"
import Website from "./sub_components/Website"
import YearFounded from "./sub_components/YearFounded"
import Date from "./sub_components/Date"

interface ICompLoaderProps {
    size?: FontSize
    value: string
    label?: string | null
}

export interface ICompLoader {
    [ key: string ]: React.FC<ICompLoaderProps>
}

const CompLoader: ICompLoader = {
    address: Address,
    bankAcc: BankAccount,
    ceo: Ceo,
    default: Default,
    email: Email,
    founded: YearFounded,
    industry: Industry,
    noe: Noe,
    phone: Phone,
    sector: Sector,
    subIndustry: SubIndustry,
    url: Url,
    website: Website,
    date: Date,
}

export default CompLoader
