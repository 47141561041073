import { IconButton, InputAdornment, TextField } from "@material-ui/core"
import { DragHandle, Search, ViewStream } from "@material-ui/icons"
import React, { ChangeEvent, useEffect, useState } from "react"
import { connect, MapDispatchToProps } from "react-redux"
import { RouteChildrenProps } from "react-router"
import { AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"
import ViewHeader from "../../components/ViewHeader"
import { disableUi, enableUi, initiateRemoveHighlights, setAllHighlights } from "../../redux/actions"
import { AppState } from "../../redux/root"
import { DataTypes } from "../../service/data/demo_data"
import Card from "./card/Card"
import CardCompact from "./card/CardCompact"
import { ICard } from "./card/redux/types"
import AppActions from "./components/AppActions"
import { AppBox, CardsContainer, SCards } from "./styles"

interface IOwnProps { }

interface IDispatchProps {
    renderAll: () => void
    removeHiLights: () => void
    disblUi: () => void
    enblUi: () => void
}

interface IStateProps {
    cards: Array<ICard<DataTypes>>
    disabled: boolean
}

const MapStateToProps = (state: AppState): IStateProps => ({
    cards: state.cards,
    disabled: state.uiDisabled,
})

const mapDispatchToProps: MapDispatchToProps<{}, IDispatchProps> = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => ({
    disblUi: () => dispatch(disableUi()),
    enblUi: () => dispatch(enableUi()),
    removeHiLights: () => dispatch(initiateRemoveHighlights()),
    renderAll: () => dispatch(setAllHighlights()),
})

type Props = IOwnProps & RouteChildrenProps & IStateProps & IDispatchProps

const AllCardsView: React.FC<Props> = (props) => {
    const { cards, history, disabled, renderAll, removeHiLights, disblUi, enblUi } = props
    const [ cardsList, setCardsList ] = useState(cards)
    const [ compact, setCompact ] = useState(true)
    const [ searchValue, setSearchValue ] = useState("")

    const renderCards = () => (
        cardsList.map((card: ICard<DataTypes>, i: number) =>
            <Card { ...card } key={ i } />,
        )
    )

    const renderCompactCards = () => (
        cardsList.map((card: ICard<DataTypes>, i: number) =>
            <CardCompact { ...card } key={ i } />,
        )
    )

    useEffect(() => {
        const load = async () => {
            disblUi()
            await renderAll()
            enblUi()
        }
        load()
        return () => {
            const goingTo = history.location.pathname
            const goingToUrlRegex = new RegExp(`(card/)`, "g")
            const goingToMatches = goingTo.match(goingToUrlRegex)
            if (!goingToMatches) {
                removeHiLights()
            }
        }
    }, [])

    useEffect(() => {
        setCardsList(cards)
    }, [ cards ])

    return (
        <SCards>
            <ViewHeader
                title={ "All Cards" }
                btn="close"
                disabled={ disabled }
                btnClickHandler={ () => history.push("/") }
            />

            <AppBox>

                <AppActions>
                    <div style={ { marginRight: "10px" } }>
                        <IconButton
                            onClick={ () => compact ? setCompact(false) : setCompact(true) }
                            aria-label="set compact view of the cards"
                            size="small">
                            { compact ? <ViewStream /> : <DragHandle /> }
                        </IconButton>
                    </div>
                    <TextField
                        value={ searchValue }
                        onChange={ (ev: ChangeEvent<HTMLTextAreaElement>) => setSearchValue(ev.target.value) }
                        id="standard-dense"
                        placeholder="Search..."
                        margin="dense"
                        fullWidth={ true }

                        InputProps={ {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search htmlColor="#c1c1c1" />
                                </InputAdornment>
                            ),
                        } }
                    />
                </AppActions>

                <CardsContainer>
                    { compact ? renderCompactCards() : renderCards() }
                </CardsContainer>

            </AppBox>
        </SCards>
    )
}

export default connect(MapStateToProps, mapDispatchToProps)(AllCardsView)
