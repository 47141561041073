import { Paper, Typography } from "@material-ui/core"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { RouteComponentProps } from "react-router"
import { AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"
import styled from "styled-components"
import ViewHeader from "../../../components/ViewHeader"
import { initiateRemoveHighlights } from "../../../redux/actions"
import { AppState } from "../../../redux/root"
import { DataTypes } from "../../../service/data/demo_data"
import ensure from "../../../utilities/ensure"
import { IApp } from "../../app_store/redux/types"
import { CardViewContainer } from "../styles"
import defaultLogo from "./../../../assets/logos/company_logo.svg"
import CardActions from "./components/CardActions"
import CompLoader from "./components/CompLoader"
import Default from "./components/sub_components/Default"
import { ICard } from "./redux/types"

interface IRouteParams {
    appId: string
    cardId: string
}

interface IOwnProps extends RouteComponentProps<IRouteParams> { }

interface IHashedInstalledApp {
    [ key: string ]: IApp
}

interface IStateProps {
    app: IApp
    cards: Array<ICard<DataTypes>>
}

interface IDispatchProps {
    removeHighlights: () => void
}

type Props = IOwnProps & IStateProps & IDispatchProps

const SCPaper = styled((props) => <Paper { ...props } />)`
    background-color: ${(props) => props.backcolor}11 !important;

    margin: 10px;
    padding: 10px;

    height: calc(100% - 90px);
    overflow-y: auto;

    @media screen and (max-width: 1024px) {
        height: calc(100% - 80px);
        margin: 5px;
        padding-bottom: 58px;

    }
`
const SCTypoWrap = styled((props) => <Typography { ...props } />)`
    && {
        text-transform: capitalize;
            @media screen and (max-width: 1024px) {
                font-size: 22px;
            }
    }
`
const SCSubTypoWrap = styled((props) => <Typography { ...props } />)`
    && {
        color: rgba(0,0,0,0.7);
        text-transform: capitalize;
        font-size: 24px;
            @media screen and (max-width: 1024px) {
                font-size: 18px;
            }
    }
`

const SCDescWrap = styled((props) => <Typography { ...props } />)`
    && {
        border-top: 2px solid ${(props) => props.bordcolor};
        border-bottom: 2px solid ${(props) => props.bordcolor};

        padding: 20px 0;
        margin: 10px 0 0 0;
    }
`

const SCContentHeader = styled.div`
    display:flex;
    align-items: flex-start;
`

const Logo = styled.img`
    object-fit: contain;

    height: 100%;
    width: 100%;
`

const LogoWrapper = styled.div<{ bordcolor: string }>`
    background-color: #fff;
    border: 1px solid ${(props) => props.bordcolor};

    margin-right: 20px;
    padding: 10px;

    flex-grow: 0;
    flex-shrink: 0;

    overflow: hidden;

    height: 100px;
    width: 100px;
`

const SubHeader = styled.div`
    flex: 1 0 100%;
`

const Header = styled.div`
    flex-shrink: 0;
    flex-grow: 0;
    overflow: hidden;
`

const MapStateToProps = (state: AppState, ownProps: IOwnProps): IStateProps => {
    const hashedInstalledApps = state.installed
        .reduce<IHashedInstalledApp>((init: IHashedInstalledApp, app: IApp) => {
            const result = { ...init }
            result[ app.id ] = app
            return result
        }, {})

    return {
        app: hashedInstalledApps[ ownProps.match.params.appId ],
        cards: state.cards,
    }
}

const MapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchProps => ({
    removeHighlights: () => dispatch(initiateRemoveHighlights()),
})

const CardView: React.FC<Props> = (props) => {
    const { app, history, match, cards, removeHighlights } = props
    const { appId, cardId } = match.params
    const { connectorName, connectorId } = app

    const card = ensure<ICard<DataTypes>>(
        cards.find((c) => c.id.toString() === cardId.toString()),
        `The card with such id does not exist.`,
    )

    const { header, content, actions } = card
    useEffect(() => {
        // connectorInstance.renderHighlights({ appId, type: connectorId })
        return () => {
            const goingTo = history.location.pathname
            const goingToUrlRegex = new RegExp(`(card/|app/${appId})`, "g")
            const goingToMatches = goingTo.match(goingToUrlRegex)
            if (!goingToMatches) {
                removeHighlights()
            }
        }
    }, [ appId, connectorId, history.location.pathname, removeHighlights ])

    return (
        <CardViewContainer>
            <ViewHeader
                title={ connectorName } btn="back"
                btnClickHandler={ () => history.push({
                    pathname: `/app/${appId}`,
                    state: {
                        dontRender: true,
                    },
                }) } />

            <SCPaper elevation={ 0 } square={ true } backcolor={ app.connectorColor }>

                <SCContentHeader>

                    <LogoWrapper bordcolor={ app.connectorColor }>
                        <Logo src={ defaultLogo } />
                    </LogoWrapper>

                    <div>
                        <Header>
                            <SCTypoWrap variant="h4" component="h1">
                                { header && header.title }
                            </SCTypoWrap>
                        </Header>

                        <SubHeader>
                            <SCSubTypoWrap variant="h5" component="h2">
                                { header && header.subheader }
                            </SCSubTypoWrap>
                        </SubHeader>

                    </div>


                </SCContentHeader>



                <SCDescWrap bordcolor={ app.connectorColor } component="div">
                    { content && content.map((item, i) => <Default key={ i } value={ item.value } label={ item.label } size="medium" link={ item.link } />) }
                </SCDescWrap>

                { actions && <CardActions actions={ actions } btnSize="medium" /> }

            </SCPaper>

        </CardViewContainer >

    )
}

export default connect(MapStateToProps, MapDispatchToProps)(CardView)
