import { DataTypes } from "../../../../service/data/demo_data"
import { ICardAction } from "../components/CardActions"

export interface IUser {
    name: string
}

export interface ICardAvatar {
    iconSrc?: string
    color?: string
    type?: string,
}

export interface ICardHeader {
    title: string
    path?: string
    subheader?: string
    avatar?: ICardAvatar
    color?: string
}

export interface ICardContentItem<T> {
    type: keyof T
    compType: string
    value: T[ keyof T ]
    label?: string | null
    link?: boolean
}

export interface ICard<T> {
    id: string
    appId: string
    path?: string
    header?: ICardHeader
    content?: Array<ICardContentItem<T>>
    color?: string
    cardProps: Array<keyof T>
    cardViewProps: Array<keyof T>
    // leaving this boolean
    // TODO define the cardActions interface
    actions?: ICardAction[]
}

export const ADD_CARD = "ADD_CARD"
export const REMOVE_CARD = "REMOVE_CARD"
export const ADD_CARDS = "ADD_CARDS"
export const REMOVE_CARDS_BY_APP_ID = "REMOVE_CARDS_BY_APP_ID"
export const REMOVE_ALL_CARDS = "REMOVE_ALL_CARDS"

export type ICardState = Array<ICard<DataTypes>>

interface IAddCard {
    type: typeof ADD_CARD
    card: ICard<DataTypes>
}

interface IRemoveCard {
    type: typeof REMOVE_CARD
    cardId: string
    appId: string
}

interface IAddCards {
    type: typeof ADD_CARDS
    cards: Array<ICard<DataTypes>>
}

interface IRemoveCardsByAppId {
    type: typeof REMOVE_CARDS_BY_APP_ID
    appId: string
}

interface IRemoveAllCards {
    type: typeof REMOVE_ALL_CARDS
}

export type ICardActions = IAddCard | IRemoveCard | IAddCards | IRemoveCardsByAppId | IRemoveAllCards
