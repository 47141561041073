import { IContactEntity, IHostCard } from "../../../host/types"
import { ICardHeader } from "../../app/card/redux/types"
import CardFactory, { IContentItem } from "./CardFactory"

class ContactCard extends CardFactory<IContactEntity> {
    constructor(
        card: IHostCard<IContactEntity>,
        appId: string,
    ) {
        super(card, appId)
        const header: ICardHeader = {
            color: card.color,
            subheader: card.entityData.description,
            title: `${card.entityData.firstName} ${card.entityData.lastName}`,
        }

        const content: Array<IContentItem<IContactEntity>> = [
            {
                compType: "phone",
                pos: 1,
                type: "phoneNumber",
            },
            {
                compType: "address",
                pos: 2,
                type: "address",
            },
        ]

        this.setCardProps([ "phoneNumber", "address" ])
        this.generateHeader(header)
        this.generateContent(content)
    }
}

export default ContactCard
