import React from "react"
import styled from "styled-components"

const SContentBox = styled.div`
    padding: 10px;
    overflow-y: auto;
    position: relative;

    height: calc(100% - 70px);

    @media screen and (max-width: 1024px) {
        padding: 5px;
    }

`

const ContentBox: React.FC = (props) => {
    return (
        <SContentBox>
            { props.children }
        </SContentBox>
    )
}

export default ContentBox
