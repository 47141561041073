import { IconButton } from "@material-ui/core"
import styled from "styled-components"

export const SCDocMenu = styled.div`
    background-color: #fff;

    position: fixed;
    bottom: 10px;
    left: 10px;

    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

    z-index: 999999;
`

export const SCButton = styled(IconButton)`
    z-index: 9999;
`

export const SCMenuBody = styled.ul<{ left: number }>`
    background-color: #fff;
    box-sizing: content-box;

    position: absolute;
    bottom: 62px;
    left: ${(props) => props.left}px;

    width: 200px;
    max-height: 800px;

    transition: left 100ms cubic-bezier(0.755, 0.05, 0.855, 0.06);

    overflow: hidden;
    word-break: break-word;
`
