const CHARACTERS_TO_NORMALIZE = {
    '\u2018': '\'', // Left single quotation mark
    '\u2019': '\'', // Right single quotation mark
    '\u201A': '\'', // Single low-9 quotation mark
    '\u201B': '\'', // Single high-reversed-9 quotation mark
    '\u201C': '"', // Left double quotation mark
    '\u201D': '"', // Right double quotation mark
    '\u201E': '"', // Double low-9 quotation mark
    '\u201F': '"', // Double high-reversed-9 quotation mark
    '\u00BC': '1/4', // Vulgar fraction one quarter
    '\u00BD': '1/2', // Vulgar fraction one half
    '\u00BE': '3/4', // Vulgar fraction three quarters
};

let normalizationRegex = null;

const normalizeText = (text) => {
    if (!normalizationRegex) {
        // Compile the regular expression for text normalization once.
        const replace = Object.keys(CHARACTERS_TO_NORMALIZE).join('');
        normalizationRegex = new RegExp(`[${replace}]`, 'g');
    }

    return text.replace(normalizationRegex, (ch) => {
        return CHARACTERS_TO_NORMALIZE[ch];
    });
}

export default normalizeText