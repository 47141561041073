import { Card as MCard, Fade } from "@material-ui/core"
import React from "react"
import { DataTypes } from "../../../service/data/demo_data"
import { SCard } from "../styles"
import CardHeader from "./components/CardHeader"
import { ICard } from "./redux/types"

type Props = ICard<DataTypes>

const CardCompact: React.FC<Props> = (props) => {

    const {
        color,
        header,
        appId,
        id,
        path,
    } = props

    return (
        <Fade in={ true } timeout={ 200 }>
            <SCard color={ color }>
                <MCard elevation={ 0 } square={ true }>
                    { /* {if there is path set explicitly render the path else --> the detailed view} */ }
                    { header && <CardHeader { ...header } path={ path ? path : `/card/${appId}/${id}` } /> }
                </MCard>
            </SCard>
        </Fade>
    )
}

export default CardCompact
