import React from "react"
import styled from "styled-components"
import { Icon, Item, Link, SubTitle, Title } from "../styles"
import defaultIcon from "./../../../assets/default_icon.svg"

interface IStoreItemProps {
    name: string
    connectorId: string
    appId?: string
    installed?: boolean
    icon: string
    shortDescription?: string
}

interface IStoreItemStyleProps {
    installed: boolean
}

type Props = IStoreItemProps

const SCStoreItem = styled.li<IStoreItemStyleProps>`
    ${Item}
`

const SCStoreLink = styled((props) => <Link { ...props } />)`
    display: flex;
`

const StoreItem: React.FC<Props> = (props) => {
    const {
        name,
        appId,
        connectorId,
        installed = false,
        icon = defaultIcon,
        shortDescription = "",
    } = props

    return (
        <SCStoreItem installed={ installed }>
            <SCStoreLink to={ `/store/app/${connectorId}/${installed ? `${appId}` : "0"}` }>
                <Icon src={ icon }></Icon>
                <div>
                    <Title>{ name }</Title>
                    <SubTitle>{ shortDescription }</SubTitle>
                </div>
            </SCStoreLink>
        </SCStoreItem>
    )
}

export default StoreItem
