import { LinearProgress, TextField } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect, MapDispatchToProps } from "react-redux"
import { RouteComponentProps } from "react-router"
import { AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"
import ContentBox from "../../components/ContentBox"
import ViewHeader from "../../components/ViewHeader"
import { disableUi, enableUi } from "../../redux/actions"
import { AppState } from "../../redux/root"
import { fetchConnectorDescriptors } from "../app/redux/actions"
import { IConnectorModel } from "../app/redux/types"
import StoreItem from "./components/StoreItem"
import StoreList from "./components/StoreList"
import { IApp } from "./redux/types"
import { SCLinearProgressWrapper, SCStore } from "./styles"

interface IStateProps {
    installed: IApp[]
    connectors: IConnectorModel[]
    disabled: boolean
}

interface IDispatchProps {
    updateConnectorsList: () => void
    disblUi: () => void
    enblUi: () => void
}

const MapPropsToState = (state: AppState): IStateProps => ({
    connectors: state.connectors,
    disabled: state.uiDisabled,
    installed: state.installed,
})

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => ({
    disblUi: () => dispatch(disableUi()),
    enblUi: () => dispatch(enableUi()),
    updateConnectorsList: () => dispatch(fetchConnectorDescriptors()),

})

type Props = IStateProps & RouteComponentProps & IDispatchProps

const Store: React.FC<Props> = (props) => {
    const { installed, connectors, history, updateConnectorsList, disblUi, enblUi, disabled } = props
    const [ connectorsUpdating, setUpdateConnectors ] = useState(false)

    // loads the connectors only when the store component is mounted
    // this prevents from fetching data at loading time of the app
    useEffect(() => {
        const load = async () => {
            if (connectors.length === 0) {
                setUpdateConnectors(true)
                disblUi()
                await updateConnectorsList()
                setUpdateConnectors(false)
                enblUi()
            }
        }
        load()
    }, [ updateConnectorsList, connectors.length, disblUi, enblUi ])

    return (
        <SCStore>

            { props.children }

            <ViewHeader
                title="store"
                btn="close"
                disabled={ disabled }
                btnClickHandler={ () => history.push("/") }
            />

            {
                !connectorsUpdating ?
                    <ContentBox>

                        <TextField
                            label="Search apps"
                            type="search"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                        />

                        { (installed.length > 0) &&
                            <StoreList title={ `In your workspace (${installed.length})` }>
                                {
                                    installed.map(
                                        (app: IApp, i: number) =>
                                            <StoreItem
                                                appId={ app.id }
                                                installed={ true }
                                                connectorId={ app.connectorId }
                                                name={ app.connectorName }
                                                icon={ app.connectorIcon }
                                                shortDescription={ app.connectorShortDescription }
                                                key={ i }
                                            />)
                                }
                            </StoreList>

                        }

                        <StoreList title="Add apps to your workspace">
                            {
                                connectors.map((con: IConnectorModel, i: number) =>
                                    <StoreItem
                                        connectorId={ con.id }
                                        name={ con.name }
                                        icon={ con.iconSrc }
                                        shortDescription={ con.shortDescription }
                                        key={ i }
                                    />,
                                )
                            }
                        </StoreList>


                    </ContentBox> :
                    <ContentBox>
                        <SCLinearProgressWrapper>
                            <LinearProgress />
                        </SCLinearProgressWrapper>
                    </ContentBox>
            }

        </SCStore>
    )
}

export default connect(MapPropsToState, mapDispatchToProps)(Store)
