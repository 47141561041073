import { ITableData } from "../../service/data/demo_data"
import DemoService from "../../service/demo_service"
import idGenerator from "../../utilities/id_generator"
import Connector from "../connector"
import { IHostCard } from "../types"
import FundReportCardIcon from "./../../assets/card_icons/table-grid_fund_report.svg"
import { ITableConnector, ITableConnectorProps } from "./types"

class TableConnector extends Connector implements ITableConnector {
    constructor(props: ITableConnectorProps) {
        const { id, descriptor, serviceHost } = props
        super({ id, descriptor, serviceHost })
    }

    public getCards(text: string) {
        const mockTableCard: IHostCard<ITableData> = {
            color: this.descriptor.color,
            entityData: {
                tableData: [
                    {
                        label: "Commitment",
                        value: "$ 886,204,550",
                    },
                ],
            },
            entityType: "table",
            iconSrc: FundReportCardIcon,
            id: idGenerator(),
            name: "Morris Venture Capital Institutional Investors LLC",
            terms: [],
        }

        const load = async () => {
            return [
                {
                    ...mockTableCard,
                    connectorId: this.descriptor.id,
                    connectorName: this.descriptor.name,
                },
            ]
        }
        return load()
    }

    public getServiceData(text: string) {
        const load = async () => {
            return await DemoService(text, "table")
        }
        return load()
    }
}

export default TableConnector
