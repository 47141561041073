import React from "react"
import styled from "styled-components"

interface IOwnProps {
    clickHandler: () => void

}

type Props = IOwnProps

const SCDocItem = styled.li`
    cursor: pointer;
    padding: 12px;

    &:hover {
        background-color: #ccc;
    }

`

const DocsItem: React.FC<Props> = (props) => {
    const { clickHandler, children } = props

    return (
        <SCDocItem onClick={ clickHandler }>
            { children }
        </SCDocItem>
    )
}

export default DocsItem
