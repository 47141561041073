import { throttle } from "lodash"
import { applyMiddleware, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly"
import { createLogger } from "redux-logger"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"
import thunkMiddleware from "redux-thunk"
import rootReducer from "./root"

const loggerMiddleware = createLogger()
const getMiddleware = () => {
    if (process.env.NODE_ENV === "production") {
        return applyMiddleware(thunkMiddleware)
    }
    return applyMiddleware(thunkMiddleware, loggerMiddleware)
}

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 })
const persistConfig = {
    key: "root",
    storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(
    persistedReducer,
    composeEnhancers(getMiddleware()),
)


export let persistor = persistStore(store)

export default store
