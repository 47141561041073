import { Dispatch } from "redux"
import closePdf from "../../../pdf.js/closeDocument"
import openPdf from "../../../pdf.js/openDocument"
import { CLOSE_APP, DocumentActions, OPEN_APP, SET_DOCUMENT_NAME, SET_DOCUMENT_TYPE, SET_DOCUMENT_URL } from "./types"

export const SetDocumentType = (documentType: string): DocumentActions => ({
    documentType,
    type: SET_DOCUMENT_TYPE,
})

export const SetDocumentName = (documentName: string): DocumentActions => ({
    documentName,
    type: SET_DOCUMENT_NAME,
})

export const SetDocumentUrl = (url: string): DocumentActions => ({
    type: SET_DOCUMENT_URL,
    url,
})

export const OpenApp = () => ({
    type: OPEN_APP,
})

export const CloseApp = () => ({
    type: CLOSE_APP,
})

export const OpenNewDocument = (type: string, source: string, name: string) =>
    (dispatch: Dispatch, getState: any) => {
        // open the new doc async
        const openDocs = async () => {
            if (type === "pdf") {
                await closePdf()
                await openPdf(source)
            }

            dispatch(SetDocumentType(type))
            dispatch(SetDocumentName(name))
            dispatch(SetDocumentUrl(source))
        }

        return openDocs()

        // reset the app state
    }
