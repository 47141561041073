import { Card as MCard, Divider, Fade } from "@material-ui/core"
import React, { useEffect } from "react"
import { DataTypes } from "../../../service/data/demo_data"
import { SCard } from "../styles"
import CardActions from "./components/CardActions"
import CardContent from "./components/CardContent"
import CardHeader from "./components/CardHeader"
import CompLoader from "./components/CompLoader"
import Default from "./components/sub_components/Default"
import { ICard } from "./redux/types"

type Props = ICard<DataTypes>

const Card: React.FC<Props> = (props) => {

    const {
        color,
        header,
        content,
        actions,
        appId,
        id,
        cardProps,
        path,
    } = props

    return (
        <Fade in={ true } timeout={ 200 }>
            <SCard color={ color }>
                <MCard elevation={ 0 } square={ true }>
                    { /* {if there is path set explicitly render the path else --> the detailed view} */ }
                    { header && <CardHeader { ...header } path={ path ? path : `/card/${appId}/${id}` } /> }

                    <Divider variant="middle" light />

                    <CardContent>
                        {
                            content &&
                            content
                                .filter((item) => cardProps.includes(item.type))
                                .map((item, i) => {
                                    const Component = CompLoader[ item.compType ]
                                    if (Component) {
                                        return <Component key={ i } value={ item.value } size="small" label={ item.label } />
                                    } else {
                                        return <Default key={ i } value={ item.value } size="small" label={ item.label } />
                                    }
                                })
                        }
                    </CardContent>

                    <Divider variant="middle" light />

                    { actions &&
                        <CardActions actions={ actions } btnSize="small">
                            {  /* TODO customize card actions and pass some functionality  */ }
                        </CardActions> }
                </MCard>
            </SCard>
        </Fade>

    )
}

export default Card
