import { IconButton } from "@material-ui/core"
import { ArrowBack, Close, Menu } from "@material-ui/icons"
import React, { useState } from "react"
import styled from "styled-components"

export interface IViewHeader {
    title: string
    btn?: string | boolean
    btnClickHandler?: any
    disabled?: boolean
}

const SViewHeader = styled.div`
    border-bottom: 1px solid #c1c1c1;

    text-transform: capitalize;
    font-size: 24px;
    font-weight: normal;

    display: flex;
    align-items: stretch;
    justify-content: space-between;

    padding: 0 16px;

    height: 70px;
`

// header organization (left to right):
// 1. navigation buttons
// 2. title
// 3. context specific controls
//

const Navigation = styled.div`
    margin-right: 24px;

`
const Title = styled.h6<{ disabled: boolean }>`
    margin: 0;

    font-size: 24px;
    color: ${props => props.disabled ? "#c1c1c1" : "#444"};
`

const RightControls = styled.div``

const SideMenu = styled.div``

const LeftSide = styled.div`
    display: flex;
    align-items: center;
`
const RightSide = styled.div``


const ViewHeader: React.FC<IViewHeader> = (props) => {
    const { title = "", btn = false, btnClickHandler, disabled = false, children } = props
    const iconSize = 24
    const btnSize = "small"
    const [ menuOpen, changeMenu ] = useState(false)

    return (
        <SViewHeader>

            <LeftSide>
                <Navigation>
                    {
                        (btn === "menu") ?
                            <IconButton disabled={ disabled } size={ btnSize } onClick={ () => {
                                changeMenu(menuOpen ? false : true)
                                btnClickHandler()
                            } }>
                                {
                                    menuOpen ?
                                        <Close height={ iconSize } ></Close> :
                                        <Menu height={ iconSize }></Menu>
                                }

                            </IconButton> : null
                    }

                    {
                        (btn === "close") ?
                            (<IconButton disabled={ disabled } size={ btnSize } onClick={ btnClickHandler }>
                                <Close height={ iconSize } ></Close>

                            </IconButton>) : null
                    }

                    {
                        (btn === "back") ?
                            (<IconButton disabled={ disabled } size={ btnSize } onClick={ btnClickHandler }>
                                <ArrowBack height={ iconSize } ></ArrowBack>
                            </IconButton>) : null
                    }
                </Navigation>

                <Title disabled={ disabled }>
                    { title }
                </Title>
            </LeftSide>


            <RightSide>
                <RightControls>

                </RightControls>

                <SideMenu>

                </SideMenu>
            </RightSide>


            { children }
        </SViewHeader>
    )
}

export default ViewHeader
