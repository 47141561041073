import { Button, CircularProgress } from "@material-ui/core"
import { Check } from "@material-ui/icons"
import React, { useState } from "react"
import { connect, MapDispatchToProps } from "react-redux"
import { RouteComponentProps } from "react-router"
import { AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"
import styled from "styled-components"
import ContentBox from "../../components/ContentBox"
import ViewHeader from "../../components/ViewHeader"
import { disableUi, enableUi } from "../../redux/actions"
import { AppState } from "../../redux/root"
import ensure from "../../utilities/ensure"
import { RemoveCardsByAppId } from "../app/card/redux/actions"
import { IConnectorModel } from "../app/redux/types"
import { appInstallation, RemoveApp } from "./redux/actions"
import {
    SCSuccessWrapper,
    SVButtonStyles,
    SVDescription,
    SVHeader,
    SVIcon,
    SVIconWrapper,
    SVInstallBox,
    SVLProgress,
    SVTitle,
} from "./styles"

interface IRouteParams {
    connectorId: string
    appId: string
}

interface IOwnProps extends RouteComponentProps<IRouteParams> { }

interface IStateProps {
    connector: IConnectorModel
    disabled: boolean
}

interface IDispatchProps {
    installApp: (descId: string) => void
    removeApp: (id: string) => void
    removeCardsById: (id: string) => void
    disblUi: () => void
    enblUi: () => void
}

type Props = IOwnProps & IStateProps & IDispatchProps

const SVButton = styled(Button)`
    ${SVButtonStyles}
`

const SuccessButton = styled(Button)`
    color: #4caf50 !important;
    ${SVButtonStyles}
`

const SVLinearProgress = styled(CircularProgress)`
    ${SVLProgress}
`

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> =
    (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchProps => ({
        disblUi: () => dispatch(disableUi()),
        enblUi: () => dispatch(enableUi()),
        installApp: (descId: string) => dispatch(appInstallation(descId)),
        removeApp: (id: string) => dispatch(RemoveApp(id)),
        removeCardsById: (id: string) => dispatch(RemoveCardsByAppId(id)),
    })

const mapStateToProps = (state: AppState, ownProps: IOwnProps): IStateProps => {
    const conId = ownProps.match.params.connectorId
    const connector = ensure<IConnectorModel>(
        state.connectors.find((con) => con.id === conId),
        "The app with such ID doesn't exist",
    )
    return {
        connector,
        disabled: state.uiDisabled,
    }
}

// component
const StoreView: React.FC<Props> = (props) => {
    const { connector, history, installApp, removeApp, removeCardsById, match, disabled, disblUi, enblUi } = props
    const { appId } = match.params
    const { name, iconSrc, description, id } = connector
    const [ appIsInstalled, setAppIsInstalled ] = useState((appId !== "0") ? true : false)
    const [ installing, setInstalling ] = useState(false)
    const [ successInstall, setSuccessInstall ] = useState(2)

    const add = async () => {
        disblUi()
        setInstalling(true)

        try {
            await installApp(id)
            setSuccessInstall(1)
        } catch (e) {
            // display error message
            // there is functionality in react that handles error !!! TODO: error handling components
            console.error(e)
            setSuccessInstall(0)
        } finally {
            setInstalling(false)
            enblUi()
        }
    }

    const remove = () => {
        setInstalling(true)
        // make it async with some timeout
        removeApp(appId)
        removeCardsById(appId)
        setInstalling(false)
        setAppIsInstalled(false)
    }

    return (
        <>
            <ViewHeader title="Store" btn="back" disabled={ disabled } btnClickHandler={ () => history.goBack() } />

            <ContentBox>
                <SVHeader>
                    <SVIconWrapper>
                        <SVIcon src={ iconSrc } alt="connector icon"></SVIcon>
                    </SVIconWrapper>
                    <SVTitle>{ name }</SVTitle>

                </SVHeader>

                <SVDescription >{ description }</SVDescription>

                <SVInstallBox>
                    {
                        (installing) ?
                            <SVLinearProgress /> :
                            (appIsInstalled) ?
                                <SVButton
                                    disabled={ installing }
                                    variant="outlined"
                                    onClick={ remove }
                                >Remove</SVButton> :
                                (successInstall === 2) ?
                                    <SVButton
                                        disabled={ installing }
                                        variant="outlined"
                                        onClick={ add }
                                    >Add</SVButton> :
                                    (successInstall === 1) ?
                                        <SCSuccessWrapper >
                                            <SuccessButton
                                                disabled={ true }
                                                variant="outlined"
                                                onClick={ add }
                                            >
                                                <Check />
                                                installed</SuccessButton>
                                        </SCSuccessWrapper> :
                                        <div>failed to install</div>

                    }
                </SVInstallBox>

            </ContentBox>
        </>

    )
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreView)
