import styled, { css } from "styled-components"

export const SCPanel = styled.ul`
    border-left: 1px solid #c1c1c1;
    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    padding: 5px;

    width: 70px;

    z-index: 999;

    @media screen and (max-width: 1024px) {
        width: 60px;
    }
`

export const SAddBtnWrapper = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;

    bottom: 10px;
    left: 0;
    right: 0;
`
export const SAllBtn = css`
    && {
        font-size: 24px;
        min-width: 100%;

        @media screen and (max-width: 1024px) {
            font-size: 18px;
        }
    }
`


/* --------------------------------------------------------
            Styles for the CPanel Button
   -------------------------------------------------------- */

export const PanelItem = styled.li`
    position: relative;
    line-height: 1;

    width: 100%;
`
// customisation of the MUI button
export const SButton = css`
    && {
        min-width: 100%;
    }

    & .MuiButton-label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`

export const PanelIcon = styled.img<{ disabled: boolean }>`
    ${({ disabled }) => disabled && `filter: grayscale(100%);`}
    width: 100%;
`

export const PanelBadge = styled.div`
    border-radius: 10px;
    background-color: ${(props) => props.color};

    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    font-size: 12px;

    position: absolute;
    top: 5px;
    right: 0;

    padding: 0 6px;

    height: 20px;
    min-width: 20px;

    @media screen and (max-width: 1024px) {
        border-radius: 10px;

        font-size: 10px;

        padding: 0 7px;
    }
`
export const PanelItemTitle = styled.span`
    font-size: 10px;
    font-weight: normal;
    word-break: break-word;

    overflow: hidden;

    width: 100%;

    @media screen and (max-width: 1024px) {
        display: none;
    }
`
