import { ArrowBack, ArrowForward, Close, Folder } from "@material-ui/icons"
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { MapDispatchToProps } from "react-redux"
import { connect } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router"
import { AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"
import { disableUi, enableUi } from "../../redux/actions"
import { AppState } from "../../redux/root"
import { resetAll } from "../app_store/redux/actions"
import DocsItem from "./DocItem"
import { CloseApp, OpenApp, OpenNewDocument } from "./redux/actions"
import { SCButton, SCDocMenu, SCMenuBody } from "./styles"

interface IOwnProps extends RouteComponentProps { }

interface IStateProps {
    appIsOpen: boolean
    disabled: boolean
}

interface IDispatchProps {
    openDoc: (type: string, source: string, name: string) => void
    opnApp: () => void
    clsApp: () => void
    reset: (docName: string) => void
    disblUi: () => void
    enblUi: () => void
}

type Props = IOwnProps & IStateProps & IDispatchProps

const mapStateToProps = (state: AppState, ownProps: IOwnProps): IStateProps => ({
    appIsOpen: state.appIsOpen,
    disabled: state.uiDisabled,
})


const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => ({
    clsApp: () => dispatch(CloseApp()),
    disblUi: () => dispatch(disableUi()),
    enblUi: () => dispatch(enableUi()),
    openDoc: (type: string, source: string, name: string) => dispatch(OpenNewDocument(type, source, name)),
    opnApp: () => dispatch(OpenApp()),
    reset: (docName: string) => dispatch(resetAll(docName)),
})

const docs = [
    {
        active: true,
        name: "Moris Venture",
        url: "/pdfs/moris_venture.pdf",
    },
    {
        active: false,
        name: "DHI Cluster Document",
        url: "/pdfs/DHI_Cluster_Protocol.docx-2.pdf",
    },
    {
        active: false,
        name: "Contact Details",
        url: "/pdfs/Contact_details.pdf",
    },
    {
        active: false,
        name: "Delivery delay",
        url: "/pdfs/Delivery_delay_Finance_Administration.pdf",
    },
    {
        active: false,
        name: "Refund inquiry",
        url: "/pdfs/Refund_inquiry_Finance_Administration.pdf",
    },
    {
        active: false,
        name: "Resignation letter",
        url: "/pdfs/Resignation_letter_HR.pdf",
    },
    {
        active: false,
        name: "Wrong number off days",
        url: "/pdfs/Wrong_number_of_days_off_finance_Administration.pdf",
    },
    {
        active: false,
        name: "Wrong transaction total",
        url: "/pdfs/Wrong_transaction_total_Finance_Administration.pdf",
    },
    {
        active: false,
        name: "Full Docs Use Cases",
        url: "/pdfs/FULL_DOC_USE_CASES.pdf",
    },
]

const DocsMenu: React.FC<Props> = (props) => {
    const { openDoc, history, appIsOpen, opnApp, clsApp, reset, disblUi, enblUi, disabled } = props
    const offsetLeft = -211
    const [ isOpen, setIsOpen ] = useState(false)
    const [ left, setLeft ] = useState(offsetLeft)
    const [ openAppBtnDisabled, setOpenAppBtnDisabled ] = useState(false)
    const menuEl = useRef(document.createElement("div"))

    const open = () => {
        setIsOpen(true)
        setLeft(0)
    }

    const clsCbk = useCallback(() => {
        setIsOpen(false)
        setLeft(offsetLeft)
    }, [ offsetLeft ])

    const close = () => {
        setIsOpen(false)
        setLeft(offsetLeft)
    }

    const openMenu = () => {
        !isOpen ? open() : close()
    }

    const openAppHandler = () => {
        appIsOpen ? clsApp() : opnApp()
    }

    useEffect(() => {
        const { innerWidth } = window
        if (innerWidth > 1024) {
            setOpenAppBtnDisabled(true)
            opnApp()
        }
    }, [ setOpenAppBtnDisabled, opnApp ])

    useLayoutEffect(() => {
        const clickOutside = (ev: MouseEvent) => {
            if (menuEl.current && !menuEl.current.contains(ev.target as Node)) {
                clsCbk()
            }
        }

        document.addEventListener("mousedown", clickOutside)

        return () => {
            document.removeEventListener("mousedown", clickOutside)
        }
    }, [ clsCbk ])

    return (
        <SCDocMenu ref={ menuEl }>
            <SCButton onClick={ openMenu } disabled={ disabled }>
                { !isOpen ? <Folder /> : <Close /> }
            </SCButton>

            { !openAppBtnDisabled && <SCButton onClick={ openAppHandler }>
                { !appIsOpen ? <ArrowBack /> : <ArrowForward /> }
            </SCButton>
            }

            <SCMenuBody left={ left }>
                { docs.map((doc: any, i: number) =>
                    <DocsItem clickHandler={ () => {
                        const load = async () => {
                            disblUi()
                            history.push("/")
                            close()
                            await openDoc("pdf", doc.url, doc.name)
                            await reset(doc.name)
                            enblUi()
                        }
                        load()
                    } } key={ i }>{ doc.name }</DocsItem>) }
            </SCMenuBody>

        </SCDocMenu>

    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocsMenu))
