import QuickBooksDescriptor from "./ quick_books/descriptor"
import BulgarianLawDescriptor from "./bulgarian_law/descriptor"
import DynamoDescriptor from "./dynamo/descriptor"
import TableDescriptor from "./table_connector/descriptor"
import TestDescriptor from "./test_app/descriptor"
import { IDescriptor } from "./types"

class ConnectorDescriptorList {
    public timeoutClear: number | null
    constructor() {
        this.timeoutClear = null
    }
    public getDescriptors = (): Promise<IDescriptor[]> => {
        if (this.timeoutClear) {
            clearTimeout(this.timeoutClear)
        }

        const descriptors = [
            new DynamoDescriptor(),
            // new SecDescriptor(),
            // new CrunchDescriptor(),
            // new USPatentsDescriptor(),
            // new USLawDescriptor(),
            // new USRegulationsDescriptor(),
            // new ZochoDescriptor(),
            new QuickBooksDescriptor(),
            new BulgarianLawDescriptor(),
            new TableDescriptor(),
            // new TestDescriptor(),
        ]

        return new Promise((res, rej) => {
            // simulating async behaviour
            this.timeoutClear = setTimeout(() => {
                res(descriptors)
            }, 200)
        })
    }
}


export default new ConnectorDescriptorList()
