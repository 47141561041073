import {
    AccountBalance,
    Apartment,
    DateRange,
    DirectionsBoat, Email,
    EventNote,
    Flag,
    Home,
    Language,
    Link,
    Person,
    Phone,
    Transform,
} from "@material-ui/icons"
import React from "react"
import styled from "styled-components"

export type FontSize = "small" | "medium" | "large"
export type IconTypes =
    "phone" |
    "address" |
    "bankAccount" |
    "email" |
    "website" |
    "url" |
    "default" |
    "ceo" |
    "industry" |
    "subIndustry" |
    "url" |
    "founded" |
    "default" |
    "noe" |
    "sector" |
    "date"

export interface IInfoProps {
    size: FontSize
    type: IconTypes
    value: string
    link?: boolean
    label?: string | null
}

type Props = IInfoProps

interface IIcons {
    [ key: string ]: React.FC<{ fontSize: string }>,
}

const SCInfo = styled.div<{ size: string }>`
        display: flex;
        align-items: center;

        margin: 5px 0;

        word-break: break-word;

        color: rgba(0, 0, 0, 0.54);
        font-size: ${(props) => props.size};
`

const Content = styled.div<{ default: boolean }>`
    margin-left: ${(props) => props.default ? 0 : "10px"};
    /* padding-left: ${(props) => props.default ? "18px" : 0}; */
`

const Anchor = styled.a`
    border-bottom: 1px solid transparent;
    color: rgba(0, 0, 0, 0.54);

    margin-left: 10px;
    transition: border-bottom 200ms ease-in-out;


    &:hover {
        border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    }
`

const Label = styled.div`
    font-weight: bold;
`

const ContentWrapper = styled.div`
`

const fontSizes = {
    large: "22px",
    medium: "18px",
    small: "14px",
}

const icons: IIcons = {
    address: Home,
    bankAcc: AccountBalance,
    ceo: Person,
    date: DateRange,
    email: Email,
    founded: Flag,
    industry: Apartment,
    noe: EventNote,
    phone: Phone,
    sector: DirectionsBoat,
    subIndustry: Transform,
    url: Link,
    website: Language,
}

const renderIcon = (type: IconTypes) => {
    const Icon = icons[ type ]
    if (Icon) {
        return <Icon fontSize="inherit" />
    } else {
        return null
    }
}

const Info: React.FC<Props> = (props) => {
    const { size, type, value, children, link = false, label } = props
    console.log(link)
    return (
        <>
            <SCInfo size={ fontSizes[ size ] }>
                { renderIcon(type) }
                {
                    link ? <Anchor target="_blank" href={ `//${value}` }>{ value }</Anchor> :
                        <ContentWrapper>
                            { label && <Label>{ label }</Label> }
                            <Content default={ type === "default" }>{ value }</Content>
                        </ContentWrapper>
                }
                { children }
            </SCInfo>
        </>

    )
}

export default Info
