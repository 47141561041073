import React from "react"
import Info, { FontSize } from "./Info"

interface IOwnProps {
    size?: FontSize
    value: string
    label?: string | null
    link?: boolean
}

type Props = IOwnProps

const Default: React.FC<Props> = (props) => {
    const { size = "medium", value, label, link } = props

    return (
        <Info type="default" size={ size } value={ value } label={ label } link={ link } />
    )
}

export default Default
