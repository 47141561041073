import ServiceHost from "../../di_service/service"
import { IDescriptor } from "../types"
import dynamoLogo from "./../../assets/logos/dynamo.png"
import DynamoConnector from "./connector"
import { IDynamoConnector } from "./types"

class DynamoDescriptor implements IDescriptor {
    public name: string = "Dynamo CRM"
    public id: string = "22"
    public color: string = "#2d7bb9"
    public iconSrc: string = dynamoLogo
    // tslint:disable-next-line: max-line-length
    public description: string = "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
    public shortDescription: string = "Parse documents with one click"

    public createConnector(id: string): IDynamoConnector {
        return new DynamoConnector({ id, descriptor: this, serviceHost: ServiceHost })
    }
}

export default DynamoDescriptor
