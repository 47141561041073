import { IHostCard } from "../../../host/types"
import { IPurchaseOrderData } from "../../../service/data/demo_data"
import { ICardAction } from "../../app/card/components/CardActions"
import { ICardHeader } from "../../app/card/redux/types"
import CardFactory, { IContentItem } from "./CardFactory"

class PurchaseOrderCard extends CardFactory<IPurchaseOrderData> {
    constructor(
        card: IHostCard<IPurchaseOrderData>,
        appId: string,
    ) {
        super(card, appId)

        const cardData = card.entityData
        const header: ICardHeader = {
            avatar: {
                iconSrc: card.iconSrc,
            },
            color: card.color,
            subheader: cardData.number,
            title: "Purchase order",
        }

        const content: Array<IContentItem<IPurchaseOrderData>> = [
            {
                compType: "default",
                label: "Issuer",
                pos: 3,
                type: "issuerName",
            },
            {
                compType: "date",
                label: "Date",
                pos: 1,
                type: "date",
            },
            {
                compType: "default",
                label: "Departure",
                pos: 2,
                type: "departureDate",
            },
            {
                compType: "default",
                label: "Invoice",
                pos: 4,
                type: "relatedInvoiceNumber",
            },
            {
                compType: "default",
                label: "Products",
                pos: 5,
                type: "products",
            },
        ]

        const actions: ICardAction[] = [
            {
                handler: "default",
                icon: "print",
                label: "print",
            },
        ]

        this.setCardProps([ "date", "issuerName" ])

        this.setCardActions(actions)
        this.generateHeader(header)
        this.generateContent(content)

    }
}

export default PurchaseOrderCard
