import { PermContactCalendar } from "@material-ui/icons"
import React from "react"
import styled from "styled-components"

interface IOwnProps {
    color?: string
    contact?: boolean
    iconSrc?: string
}

const SAvatar = styled.div`
    color: ${(props) => props.color};

    display: flex;
    align-items: center;
    justify-content: center;
`

const SCardIcon = styled.img`
    object-fit: cover;

    height: 30px;
    width: 30px;
`

const Avatar: React.FC<IOwnProps> = (props) => {
    const defaultIcon = "https://cdn2.iconfinder.com/data/icons/metro-uinvert-dock/256/Default.png"
    const defaultColor = "#bdbdbd"
    const { color = defaultColor, contact, iconSrc = defaultIcon } = props

    return (
        <SAvatar color={ color }>
            { contact && <PermContactCalendar fontSize="large" color="inherit" /> }
            { (iconSrc && !contact) && <SCardIcon src={ iconSrc } /> }
        </SAvatar>
    )
}

export default Avatar
