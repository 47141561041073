import DemoService from "../../service/demo_service"
import uniq from "../../utilities/unique_values"
import Connector from "../connector"
import { IHostCard } from "../types"
import { IBulgarianLawConnector, IBulgarianLawConnectorProps } from "./types"

class BulgarianLawConnector extends Connector implements IBulgarianLawConnector {
    constructor(props: IBulgarianLawConnectorProps) {
        const { id, descriptor, serviceHost } = props
        super({ id, descriptor, serviceHost })
    }

    public getCards(text: string) {
        const load = async () => {
            const data = await this.getServiceData(text)
            const allEntityItems = data
                .map((d: any) => d.entityItems)
                .flat(1)
            const cards = uniq<IHostCard<any>>(
                allEntityItems,
                (item) => item.id,
            )
            return cards
                .map((card) => Object.assign(
                    {},
                    card,
                    {
                        color: this.descriptor.color,
                        connectorId: this.descriptor.id,
                        connectorName: this.descriptor.name,
                    },
                ))
        }
        return load()
    }

    public getServiceData(text: string) {
        const load = async () => {
            return await DemoService(text, "bulgarian_law")
        }
        return load()
    }

}

export default BulgarianLawConnector
