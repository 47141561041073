import { Navigation } from "@material-ui/icons"
import React from "react"
import { Route, Switch } from "react-router"
import styled from "styled-components"
import Notification from "../components/Notification"
import AllCardsView from "./app/AllCardsView"
import AppView from "./app/AppView"
import CardView from "./app/card/CardView"
import CStore from "./app_store/Store"
import StoreView from "./app_store/StoreView"
import DefaultView from "./DefaultView"
import CPanel from "./panel/CPanel"
import TableView from "./TableView"
import View from "./View"

const SLeftPanel = styled.div`
    border-left: 1px solid #c1c1c1;
    position: relative;
    background-color: #ffffff;
`
const LeftPanel: React.FC = () => {

    return (

        <SLeftPanel>

            <CPanel />

            <View>

                <Switch>

                    <Route path="/" exact component={ DefaultView } />
                    <Route path="/store" exact component={ CStore } />
                    <Route
                        // need the coonector id to load specific info related to the connector type
                        // but we can store that info in the app object in the redux store
                        path="/app/:appId"
                        component={ AppView } />

                    <Route
                        path="/card/all"
                        exact
                        component={ AllCardsView }
                    />

                    <Route
                        path="/store/app/:connectorId/:appId"
                        component={ StoreView }
                    />

                    <Route
                        path="/card/:appId/:cardId"
                        component={ CardView }
                    />

                    <Route
                        path="/table/:appId/:cardId"
                        render={ (props) => (
                            <Notification closeHandler={ () => props.history.goBack() }>
                                <TableView { ...props } />
                            </Notification>
                        ) }
                    />

                </Switch>

            </View>

        </SLeftPanel >
    )
}

export default LeftPanel
