import { Dispatch } from "redux"
import extractText from "../pdf.js/extract_text"
import removeHighlights from "../pdf.js/removeHighlights"
import renderHighlights from "../pdf.js/renderHighlights"
import DemoService from "../service/demo_service"
import generateDemoMatches from "./../service/data/demo_data"
import { AppState } from "./root"
import {
    DISABLE_UI, ENABLE_UI, REMOVE_HIGHLIGHTS, SET_HIGHLIGHTS,
} from "./types"

const demoMatches = generateDemoMatches()

const SetHighlights = () => ({
    type: SET_HIGHLIGHTS,
})

const RemoveHighlights = () => ({
    type: REMOVE_HIGHLIGHTS,
})

export const setHighlights = (connectorId: string, appId: string) => {
    // fix this, it's awful
    const services: { [ key: string ]: keyof typeof demoMatches } = {
        22: "dynamo",
        11: "bulgarian_law",
        77: "quick_books",
        69: "test",
        53: "table",
    }

    return async (dispatch: Dispatch, getState: any) => {
        const text = await extractText()
        const terms = await DemoService(text, services[ connectorId ])
        const cardIds = getState().cards.filter((card: any) => card.appId === appId)
        const highlightData = terms.map((term) => Object.assign({}, term, { appId }))
        await renderHighlights(highlightData, appId)
        dispatch(SetHighlights())
    }
}

export const setAllHighlights = () => {
    const services: { [ key: string ]: keyof typeof demoMatches } = {
        22: "dynamo",
        11: "bulgarian_law",
        77: "quick_books",
        69: "test",
        53: "table",
    }

    return async (dispatch: Dispatch, getState: any) => {
        const { installed } = getState()
        const text = await extractText()

        const highlightData = []


        for (const app of installed) {
            const terms = await DemoService(text, services[ app.connectorId ])
            const data = terms.map((term) => Object.assign({}, term, { appId: app.id }))
            highlightData.push(...data)
            await renderHighlights(highlightData, app.id)
            dispatch(SetHighlights())
        }


    }
}

export const initiateRemoveHighlights = () => (dispatch: Dispatch) => {
    removeHighlights()
    dispatch(RemoveHighlights())
}

export const disableUi = () => ({
    type: DISABLE_UI,
})

export const enableUi = () => ({
    type: ENABLE_UI,
})
