import { IServiceHost } from "../di_service/service"
import { IConnector, IConnectorProps, IDescriptor } from "./types"

class Connector implements IConnector {
    public id: string
    public descriptor: IDescriptor
    public serviceHost: IServiceHost
    constructor(props: IConnectorProps) {
        const { id, descriptor, serviceHost } = props
        this.id = id
        this.descriptor = descriptor
        this.serviceHost = serviceHost
    }
}

export default Connector
