// f beautiful
// copied from here --> https://stackoverflow.com/questions/54738221/typescript-array-find-possibly-undefind

const ensure = <T>(
    argument: T | undefined | null,
    message: string = "The value which was expected to exist doesn't",
): T => {
    if (argument === undefined || argument === null) {
        throw new Error(message)
    }

    return argument
}

export default ensure
