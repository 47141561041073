import extractText from "../pdf.js/extract_text"
import highlight from "../pdf.js/highlight"
import renderHighlights from "../pdf.js/renderHighlights"

export interface IPdfService {
    highlight: (data: any) => void
    removeHighlights: () => void
    extractText: () => Promise<string>
}

export interface IServiceHost {
    getPdfService: () => IPdfService
}

// DI requires injector to resolve the dependeincies
// will do that in the near future
// TODO DI in TS
// example ---> https://nehalist.io/dependency-injection-in-typescript/

class ServiceHost implements IServiceHost {
    public getPdfService = () => new PdfService()
}
// still not sure
// tslint:disable-next-line: max-classes-per-file
class PdfService implements IPdfService {
    public highlight = (data: any) => {
        renderHighlights(data)
    }

    public removeHighlights = () => highlight.remove_tags()
    public extractText = () => extractText()
}

export default new ServiceHost() as ServiceHost
