import { Navigation, PhonelinkRing } from "@material-ui/icons"
import { IHostCard } from "../../../host/types"
import { IOrganizationData } from "../../../service/data/demo_data"
import { ICardAction } from "../../app/card/components/CardActions"
import { ICardHeader } from "../../app/card/redux/types"
import CardFactory, { IContentItem } from "./CardFactory"

class CompanyCard extends CardFactory<IOrganizationData> {

    constructor(card: IHostCard<IOrganizationData>, appId: string) {
        super(card, appId)
        const header: ICardHeader = {
            avatar: {
                iconSrc: card.iconSrc,
            },
            color: card.color,
            path: "",
            subheader: card.entityData.type,
            title: card.entityData.name,
        }

        const content: Array<IContentItem<IOrganizationData>> = [
            {
                compType: "website",
                label: "Website",
                pos: 1,
                type: "website",
            },
            {
                compType: "founded",
                label: "Year Founded",
                pos: 2,
                type: "yearFounded",
            },
            {
                compType: "ceo",
                label: "Ceo",
                pos: 3,
                type: "ceo",
            },
            {
                compType: "phone",
                label: "Phone",
                pos: 4,
                type: "phone",
            },
            {
                compType: "address",
                label: "Address",
                pos: 5,
                type: "address",
            },
            {
                compType: "default",
                label: "Noe",
                pos: 6,
                type: "noe",
            },
            {
                compType: "default",
                label: "Sector",
                pos: 7,
                type: "sector",
            },
            {
                compType: "default",
                label: "Industry",
                pos: 8,
                type: "industry",
            },
            {
                compType: "default",
                label: "Sub Industry",
                pos: 9,
                type: "subIndustry",
            },
            {
                compType: "default",
                label: "Bank Account",
                pos: 10,
                type: "bankAccount",
            },

        ]

        const actions: ICardAction[] = [
            {
                handler: "default",
                icon: "phoneLink",
                label: "dial-up",
            },
            {
                handler: "default",
                icon: "navigation",
                label: "dial-up",
            },
        ]

        this.generateHeader(header)
        this.generateContent(content)
        this.setCardProps([ "website", "yearFounded", "ceo" ])
        this.setCardActions(actions)
    }
}

export default CompanyCard
