import { IHostCard } from "../../../host/types"
import { ICardAction } from "../../app/card/components/CardActions"
import { ICard, ICardContentItem, ICardHeader } from "../../app/card/redux/types"

export interface IContentItem<T> {
    type: keyof T
    compType: string
    pos: number
    label?: string | null | undefined
    link?: boolean
}

class CardFactory<T> implements ICard<T> {
    public card: IHostCard<T>
    public id: string
    public appId: string
    public header?: ICardHeader
    public content?: Array<ICardContentItem<T>>
    public color: string
    public actions: ICardAction[]
    public cardProps: Array<keyof T> = []
    public cardViewProps: Array<keyof T> = []
    public pos = 0
    public path?: string

    constructor(card: IHostCard<T>, appId: string) {
        this.card = card
        this.id = card.id
        this.appId = appId
        this.content = []
        this.color = card.color
        this.actions = []
    }

    public generateHeader(cardHeader: ICardHeader) {
        this.header = cardHeader
    }

    public generateContent(content: Array<IContentItem<T>>) {
        content.sort((a, b) => a.pos - b.pos)
        const newContent = content.map((item) => ({
            compType: item.compType,
            type: item.type,
            value: this.card.entityData[ item.type ],
            label: item.label,
        }))

        this.content = newContent.filter((item) => this.card.entityData[ item.type ])
    }

    public setPath(path: string) {
        this.path = path
    }

    public setCardProps(props: Array<keyof T>) {
        this.cardProps = props
    }

    public setCardViewProps(props: Array<keyof T>) {
        this.cardViewProps = props
    }

    public setCardActions(actions: ICardAction[]) {
        this.actions = actions
    }
}

export default CardFactory
