import normalizeText from "./normalize_text";
const tag_name = "custom-highlight";
let keyword_separator = "";

const set_keyword_separator = (value) => {
    keyword_separator = value
}

const PDFViewerApplication = window.PDFViewerApplication

let build_tag_attr = (match_data) => {
    // this is bound to a specificity and doesn't work on universal custom highlight
    // rewrite!
    // write logic that handles attribute data and is passed to the highlights somehow
    let cls = [];
    let type = match_data.entityItems.length === 1 ? `${match_data.serviceName} ${match_data.entityItems[0].entityType}` : "multi";
    let attr = {
        "data-id": match_data.entityItems.map(({ id }) => id).join(","),
        "data-react-app-id": match_data.appId,
        "data-react-app-type": match_data.serviceName,
        "data-type": type,
        "data-text": match_data.originalName,
        "data-showinfo": true
    };

    if (match_data.attr) {
        attr = Object.assign(attr, match_data.attr);
    }

    if (match_data.entityItems.length > 1) {
        attr["data-multi-info"] = true
    }

    cls.push(type);

    return {
        cls: cls,
        attr: attr
    };
};


const convertMatches = (matches, page_content) => {
    var i = 0;
    var iIndex = 0;
    var bidiTexts = page_content.items;
    var end = bidiTexts.length - 1;
    var ret = [];

    for (var m = 0, len = matches.length; m < len; m++) {
        // Calculate the start position.
        var [matchIdx, queryLen, matchedText] = matches[m];

        // Loop over the divIdxs.
        while (i !== end && matchIdx >= (iIndex + bidiTexts[i].str.length)) {
            iIndex += bidiTexts[i].str.length;
            i++;
        }

        if (i === bidiTexts.length) {
            console.error('Could not find a matching mapping');
        }

        var match = {
            matchedText: matchedText,
            begin: {
                divIdx: i,
                offset: matchIdx - iIndex
            }
        };

        // Calculate the end position.
        matchIdx += queryLen;

        // Somewhat the same array as above, but use > instead of >= to get
        // the end position right.
        while (i !== end && matchIdx > (iIndex + bidiTexts[i].str.length)) {
            iIndex += bidiTexts[i].str.length;
            i++;
        }

        match.end = {
            divIdx: i,
            offset: matchIdx - iIndex
        };
        ret.push(match);
    }

    return ret;
};

let renderMatches = (matches, page_content, text_divs, prometheus_hash) => {
    // Early exit if there is nothing to render.
    if (matches.length === 0) {
        return;
    }
    var bidiTexts = page_content.items;
    var textDivs = text_divs;
    var prevEnd = null;
    var selectedMatchIdx = 0; //0;
    var highlightAll = true;
    var infinity = {
        divIdx: -1,
        offset: undefined
    };

    function beginText(begin, className, attr = {}) {
        var divIdx = begin.divIdx;
        if (!textDivs[divIdx]) {
            return;
        }
        textDivs[divIdx].textContent = '';
        appendTextToDiv(divIdx, 0, begin.offset, className, attr);
    }

    function appendTextToDiv(divIdx, fromOffset, toOffset, className, attr = {}) {
        var div = textDivs[divIdx];
        var content = bidiTexts[divIdx].str.substring(fromOffset, toOffset);
        var node = document.createTextNode(content);

        if (!div)
            return;

        if (className) {
            var span = document.createElement(tag_name);

            // eslint-disable-next-line no-unused-vars
            for (let key in attr) {
                span.setAttribute(key, attr[key])
            }
            span.className = className;
            span.appendChild(node);
            div.appendChild(span);
            return;
        }
        div.appendChild(node);
    }

    var i0 = selectedMatchIdx, i1 = i0 + 1;
    if (highlightAll) {
        i0 = 0;
        i1 = matches.length;
    }

    let duplicate_index = {};

    for (var i = i0; i < i1; i++) {
        var match = matches[i];
        var begin = match.begin;
        var end = match.end;
        let matchedText = match.matchedText;

        let key = matchedText.toLowerCase();
        let index = duplicate_index[key];

        duplicate_index[key] = duplicate_index[key] ? duplicate_index[key] + 1 : 1;

        let match_data = prometheus_hash[key + (index || "")] || prometheus_hash[key];

        if (!match_data) {
            console.log("not_found")
            continue;
        }

        let { cls, attr } = build_tag_attr(match_data);

        // Match inside new div.
        if (!prevEnd || begin.divIdx !== prevEnd.divIdx) {
            // If there was a previous div, then add the text at the end.
            if (prevEnd !== null) {
                appendTextToDiv(prevEnd.divIdx, prevEnd.offset, infinity.offset, "", attr);
            }
            // Clear the divs and set the content until the starting point.
            beginText(begin, "", attr);
        } else {
            appendTextToDiv(prevEnd.divIdx, prevEnd.offset, begin.offset, "", attr);
        }

        if (begin.divIdx === end.divIdx) {
            appendTextToDiv(begin.divIdx, begin.offset, end.offset, cls.join(" "), attr);
        } else {
            appendTextToDiv(begin.divIdx, begin.offset, infinity.offset, cls.join(" ") + ' begin', attr);
            for (var n0 = begin.divIdx + 1, n1 = end.divIdx; n0 < n1; n0++) {
                textDivs[n0].className = cls.join(" ") + ` middle ${tag_name}`;
                // eslint-disable-next-line no-loop-func
                Object.keys(attr).forEach(key => {
                    textDivs[n0].setAttribute(key, attr[key])
                })
            }
            beginText(end, cls.join(" ") + ' end', attr);
        }
        prevEnd = end;
    }

    if (prevEnd) {
        appendTextToDiv(prevEnd.divIdx, prevEnd.offset, infinity.offset);
    }
};

let get_page_content = async (pdf_document, page_number) => {
    let page = await pdf_document.getPage(page_number);
    return await page.getTextContent();
};

let get_text_from_page_content = (page_content) => {
    return page_content.items.map(item => item.str).join("")
};

let get_text_divs = (page_index) => {
    let pageView = window.PDFViewerApplication.pdfViewer.getPageView(page_index);
    return (pageView && pageView.textLayer) ? pageView.textLayer.textDivs : null
};

let find_matches = (prometheus_hash, page_text) => {
    let _build_reg_exp = (prometheus_hash) => {
        let _collect_prometheus_matches = (prometheus_hash) =>
            Object.keys(prometheus_hash).map(key => prometheus_hash[key].originalName.toLowerCase());

        let _encode_words = (words) =>
            // eslint-disable-next-line no-useless-escape
            (words || []).map((word => word.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")));

        let _sort_words_by_length = (words) =>
            (words || []).sort((a, b) => b.length - a.length);

        let words = _collect_prometheus_matches(prometheus_hash);
        let encoding_words = _encode_words(words);
        let sorted_words = _sort_words_by_length(encoding_words);
        let regStr = (keyword_separator ? keyword_separator + "(" : "") + sorted_words.join("|") + (keyword_separator ? ")" : "");
        return new RegExp(regStr, "gim")
    };


    let reg_exp = _build_reg_exp(prometheus_hash);

    let text = normalizeText(page_text);

    let result = [];
    let matches;
    let last_index = -1;
    do {
        matches = reg_exp.exec(text);
        if (matches) {
            let index = keyword_separator ? matches.index + 1 : matches.index;
            let text = keyword_separator ? matches[2] : matches[0].trim();
            let length = text.length;
            result.push([index, length, text]);
            if (last_index === matches.index)
                matches = null;
            else
                last_index = matches.index;

        }
    } while (matches);

    return result
};

// eslint-disable-next-line no-unused-vars
let find_matches_with_find_controller = (prometheus_hash, page_text, page_index) => {
    let all_matches = [];
    PDFViewerApplication.findController.pageContents[page_index] = page_text;

    Object.keys(prometheus_hash).forEach(key => {
        let text_query = prometheus_hash[key].originalName.toLowerCase();
        PDFViewerApplication.findController.state = PDFViewerApplication.findController.state || {};
        PDFViewerApplication.findController.state.query = text_query;
        PDFViewerApplication.findController.calcFindMatch(page_index);
        let matches = JSON.parse(JSON.stringify(PDFViewerApplication.findController.pageMatches));
        matches = matches.map(item => [item[0], text_query.length, text_query.trim()]);
        all_matches = all_matches.concat(matches)
    });

    return all_matches.sort((a, b) => a[0] - b[0]);
};

let build_prometheus_hash = prometheus_result => {
    let duplicate_index = {};
    return prometheus_result.reduce((result, item) => {
        let key = item.originalName.toLowerCase();
        if (result[key]) {
            let index = duplicate_index[key] || 1;
            duplicate_index[key] = index + 1;
            key += index
        }

        result[key] = item;
        return result;
    }, {})
};

let render = async (prometheus_result, page_number, pdf_document) => {
    let page_index = page_number - 1;
    let prometheus_hash = build_prometheus_hash(prometheus_result);

    let page_content = await get_page_content(pdf_document, page_number);
    let page_text = get_text_from_page_content(page_content);

    let matches = find_matches(prometheus_hash, page_text, page_index);

    let converted_matches = convertMatches(matches, page_content);

    let text_divs = get_text_divs(page_index);
    if (!text_divs) { return }

    renderMatches(converted_matches, page_content, text_divs, prometheus_hash);
};

let remove_tags = (page_number) => {
    page_number = page_number ? page_number : window.PDFViewerApplication ? window.PDFViewerApplication.page : null

    if (!page_number) {
        return
    }

    let page_index = page_number - 1;
    let divs = get_text_divs(page_index);
    for (let i = 0; i < divs.length; i++) {
        let html = divs[i].innerHTML;
        html = html.replace(/<\\?custom-highlight([^>]+)?>/g, "");
        divs[i].innerHTML = html;
    }
};

export default {
    tag_name: tag_name,
    set_keyword_separator,
    render,
    remove_tags
};