import { Link as RouterLink } from "react-router-dom"
import styled, { css } from "styled-components"

export const SCStore = styled.div`
    height: 100%;
`
export const SCStoreList = styled.ul`
    margin-bottom: 30px;
`
export const ListTitle = styled.li`
    font-size: 12px;
    color: #666666;

    padding: 10px 0;
`
export const Item = css`
    border-top: 1px solid #ccc;
    cursor: pointer;

    color: #444444;
    font-size: 20px;
    text-transfrom: capitalize;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: 20px;

    &:hover {
        background-color: #f1f1f1;
    }

    @media screen and (max-width: 1024px) {
        padding: 10px;
    }
`
export const Link = styled(RouterLink)`
    display: block;
    color: #444;
`

export const Icon = styled.img`
    margin-right: 20px;

    @media screen and (max-width: 1024px) {
        margin-right: 10px;
    }

    height: 40px;
    width: 40px;
`

export const Title = styled.div`
    font-size: 16px;
    font-weight: bold;
`
export const SubTitle = styled.div`
    font-size: 14px;
    color: #777;
`


/* ----------------------------------------------------------
                    STORE VIEW STYLES
----------------------------------------------------------- */

// const SCStoreView = styled.div`
//     height: 100%;
//     overflow-y: auto;
// `

export const SVHeader = styled.div`
    border-bottom: 1px solid #c1c1c1;

    padding: 10px;
`

export const SVIconWrapper = styled.div`
    margin: 0 auto 30px;

    width: 100px;
    height: 100px;
`

export const SVIcon = styled.img`
    border-radius: 16px;

    object-fit: cover;

    height: 100%;
    width: 100%;
`

export const SVTitle = styled.h2`
    font-size: 28px;
`

export const SVDescription = styled.div`
    padding: 10px;
`

export const SVButtonStyles = css`
    & .MuiButton-label {
        font-size: 18px;
    }

    /* width: 100%; */
`
export const SVInstallBox = styled.div`
    display: flex;
    align-items: center;
    /* justify-content: center; */

    padding: 20px 10px;

    margin-bottom: 58px;

    height: 83px;
`

export const SVLProgress = css`
    && {
        /* flex-basis: 85%; */
    }
`

export const SCLinearProgressWrapper = styled.div`
    height: 10px;
    width: 80%;

    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const SCSuccessWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
