// checks array of string for uniqeness
type Ifn<T> = (item: T) => string | number

const uniq = <T>(arr: T[], fn?: Ifn<T>): T[] => {
    const result = arr.reduce<{ [ key: string ]: T }>((res: { [ key: string ]: T }, item: T) => {
        if (fn) {
            res[ `${fn(item)}` ] = item
        } else {
            res[ `${item}` ] = item
        }
        return res
    }, {})
    return Object.keys(result).map((key) => result[ key ])
}

export default uniq
