import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  /* overrides the pdf.js viewer.css */

  html {
    font-size: 16px;
  }

  /* end overriding */
  body {
    margin: 0;
    padding-right: 0;

    font-family: 'Noto Sans', sans-serif;

    @media screen and (max-width: 1024px) {
      padding-right: 0;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  svg {
    height: 100%;
    width: 100%;
  }

  .page {
    margin: 1px auto 8px auto !important;
  }


  /* Transition definitions */

  .router-appear,
  .router-enter {
      opacity: 0;
      transform: translateX(-30px);
      z-index: 1;
  }
  .router-appear-active,
  .router-enter.router-enter-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 300ms cubic-bezier(0.215, 0.610, 0.355, 1) 150ms, transform 300ms cubic-bezier(0.215, 0.610, 0.355, 1);
  }

  .router-exit {
      opacity: 1;
  }

  .router-exit.router-exit-active {
      opacity: 0;
      transition: opacity 150ms linear;
  }

  .c-item-appear,
  .c-item-enter {
    height: 0;
    overflow: hidden;
  }

  .c-item-appear-active,
  .c-item-enter.c-item-enter-active {
    height: auto;
    overflow: initial;
    transition: height 300ms;
  }

  .c-item-exit {
    overflow: initial;
    height: auto;
  }

  .c-item-exit.c-item-exit-active{
    overflow: hidden;
    height: 0;
    transition: height 300ms;
  }

  custom-highlight {
    transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);
    display: inline-block;
    height: 1px;
    vertical-align: bottom;
    animation: effect 400ms 50ms cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
  }

  @keyframes effect {
    to {
      height: 0.9rem;
    }
  }

  @media screen and (max-width: 1024px) {
    @keyframes effect {
      to {
        height: 7px;
      }
    }
  }

.bulgarian_law {
  cursor: pointer;
  background-color: #aa97cc;
}

  .multi {
    cursor: pointer;
    background-color: rgba(50, 50, 50, 0.8);
  }

  .dynamo {
    cursor: pointer;
    background-color: rgba(196,24,87, 0.9);
  }

  .dynamo.contact {
    cursor: pointer;
    background-color: rgba(196,24,87, 0.9);
  }

  .dynamo.contact:hover {
    cursor: pointer;
    background-color: rgba(0,119,227, 0.9);
    border: 1px solid rgba(0,66,200, 0.8);
  }

  .dynamo.organization {
    cursor: pointer;
    background-color: rgb(45, 123, 185, 0.8);
  }

  .quick_books {
    cursor: pointer;
    background-color: #2ca01c;
  }

  .test {
    cursor: pointer;
    background-color: rgba(96,24,87, 0.9);
  }

  .toolbar {
    display: none;
  }

  #viewerContainer {
    top: 0px;
  }
`

export default GlobalStyle
