import { IconButton, Paper, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from "@material-ui/core"
import { GridOn, ImportExport } from "@material-ui/icons"
import React from "react"
import { RouteComponentProps } from "react-router"
import styled from "styled-components"
import Notification from "../components/Notification"

interface IOwnProps extends RouteComponentProps { }

type Props = IOwnProps

const TableView: React.FC<Props> = (props) => {
    // hardcoded table


    const rows = [
        [
            "Milton Highlands Fund",
            "275,200,000",
            "9,179,353",
            "(2,583,050)",
            "(27,973)",
            "(3,370)",
            "(1,091,550)",
            "5,473,410",
        ],
        [
            "Langshire House Legacy Fund",
            "184,800,000",
            "6,119,568",
            "(1,722,032)",
            "(18,648)",
            "(2,247)",
            "(727,700)",
            "3,648,941",
        ],
        [
            "Norsk LLC",
            "142,400,000",
            "4,059,788",
            "(861,016)",
            "(9,325)",
            "(1,123)",
            "(363,850)",
            "1,824,474",
        ],
        [
            "Minx Corporation Employees' Pension Trust Inc..",
            "62,800,000",
            "3,059,788",
            "(861,016)",
            "(9,325)",
            "(1,123)",
            "(363,850)",
            "1,824,474",
        ],
        [
            "Connor & Mane Inc.",
            "14,900,000",
            "1,429,891",
            "(430,508)",
            "(4,662)",
            "(562)",
            "(181,925)",
            "912,234",
        ],
        [
            "Miriam Meir Endowment",
            "11,960,000",
            "1,253,910",
            "(344,407)",
            "(3,730)",
            "(449)",
            "(145,540)",
            "729,784",
        ],
    ]

    const totalRow = [
        "Total",
        "$886,204,550",
        "$29,346,280",
        "$(8,257,972)",
        "$(89,972)",
        "$(10,774)",
        "$(3,489,669)",
        "$(17,498,435)",
    ]

    return (
        <Table aria-label="spanning table">
            <TableHead>
                <TableRow>
                    <TableCell>Investors</TableCell>
                    <TableCell>Commitment</TableCell>
                    <TableCell>Investor's Capital at March 31, 2019</TableCell>
                    <TableCell>Cash Distributions</TableCell>
                    <TableCell>Net Investment Loss</TableCell>
                    <TableCell>Net Realized Loss on Investment Transaction</TableCell>
                    <TableCell>Net Change in Unrealized Depreciation of Investment</TableCell>
                    <TableCell>Investor's Capital at June 30, 2019</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    rows.map((row) => (
                        <TableRow>
                            { row.map((cell) => <TableCell>{ cell }</TableCell>) }
                        </TableRow>
                    ))
                }

                <TableRow>
                    { totalRow.map((cell) => <TableCell>{ cell }</TableCell>) }
                </TableRow>
            </TableBody>
            <TableFooter>
                <IconButton onClick={ () => console.log("jokin'") }>
                    <ImportExport />
                </IconButton>
                <IconButton onClick={ () => window.open("./tablica.xls") }>
                    <GridOn />
                </IconButton>
            </TableFooter>
        </Table>

    )
}

export default TableView
