const checksAndSetsDispatchToDOM = () => {
    const PDFViewerApp = window.PDFViewerApplication

    if (PDFViewerApp && PDFViewerApp.eventBus) {
        if (PDFViewerApp.eventBus._dispatchToDOM) {
            return true
        } else {
            PDFViewerApp.eventBus._dispatchToDOM = true
            return true
        }
    } else {
        return false
    }
}

export default checksAndSetsDispatchToDOM