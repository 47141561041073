import idGenerator from "../../utilities/id_generator"
import billOfLadingIconQuickBooks from "./../../assets/card_icons/bill_of_lading_icon_quick_books.svg"
import contractIconQuickBooks from "./../../assets/card_icons/contract_icon_quick_books.svg"
import testEntityIcon from "./../../assets/card_icons/cpu.svg"
import individualIconDynamo from "./../../assets/card_icons/individual_dynamo.svg"
import individualIconQuickBooks from "./../../assets/card_icons/individual_quick_books.svg"
import invoiceIcon from "./../../assets/card_icons/invoice_icon.svg"
import organizationIconDynamo from "./../../assets/card_icons/organization_dynamo.svg"
import organizationIconQuickBooks from "./../../assets/card_icons/organization_quick_books.svg.svg"
import purchaseOrderIconQuickBooks from "./../../assets/card_icons/purchase_order_quick_books.svg"
import personIcon from "./../../assets/person.svg"
import billsOfLading from "./json_files/bill_of_lading.json"
import individuals from "./json_files/individuals.json"
import invoice from "./json_files/invoice.json"
import law from "./json_files/law.json"
import organizations from "./json_files/organizations.json"
import purchaseOrders from "./json_files/purchase_order.json"
import leaveRequest from "./json_files/request_for_leave.json"
import roleCodes from "./json_files/role_codes.json"


// time wasted
export interface IOrganizationData {
    name: string
    vatin?: string
    type: string
    address?: string
    phone?: string
    website?: string
    bankAccount?: string
    noe?: string
    sector?: string
    industry?: string
    subIndustry?: string
    bod?: string
    ceo?: string
    yearFounded?: string
}

export interface IIndividualData {
    name: string
    organization: string
    role: string
    roleCode: string
    department?: string
    email?: string
    phone?: string
    officeAddress?: string
    industry?: string
    personalWebsite?: string
    university?: string
    contractNumber: string
}

export interface IInvoiceData {
    number: number | string
    issueDate: string
    dueDate: string
    recipientName: string
    supplierName: string
    supplierVatin: string | number
    recipientVatin: string | number
    total: string | number
}

export interface IPurchaseOrderData {
    date: string
    number: string
    relatedInvoiceNumber: string
    departureDate: string
    products: string
    issuerName: string
}

export interface IBillOfLadingData {
    billOfLadingNumber: string
    notifyParty: string
    preCarriageBy: string
    placeOfReceipt: string
    portOfLoading: string
    oceanVesselVoyage: string
    portOfDischarge: string
    placeOfDelivery: string
    numberAndKindOfPackages: string
    grossWeight: string
    bookingNumber: string
    totalNumberOfContainersAndPackages: string
    freightCharges: string
    dateOnBoard: string
    dateOfIssue: string
    placeOfIssue: string
    numberOfOriginals: string
    carriersAgent: string
}

export interface IContactData {
    firstName: string
    lastName: string
    address: string
    phoneNumber: string
    description: string
    email: string
}

export interface ITableCell {
    label: string
    value: string
}

export interface ITableData {
    tableData: ITableCell[]
}

export interface ILawData {
    name: string
    source: string
    lastUpdate: string
    title?: any
    paragraph_1?: string
    paragraph_2?: string
    paragraph_3?: string
}

export interface ILeaveRequest {
    number: string
    employeeName: string
    contractNumber: string
}

// tslint:disable-next-line: max-line-length
export type DataTypes = IOrganizationData | IIndividualData | IInvoiceData | IPurchaseOrderData | IBillOfLadingData | IContactData | ITableData

export interface IEntity {
    id: number | string
    name: string
    entityType: string
    iconSrc: string
    terms: string[]
    entityData: DataTypes
}

interface ITerm {
    type: "prop" | "string"
    value: string
}

const generateEntities = (collection: any[], type: string, iconSrc: string, rawTerms: ITerm[]): IEntity[] => {
    const generateTerms = (terms: ITerm[], node: any) => {
        return terms.map((term) => {
            let termValue = null

            if (term.type === "prop") {
                termValue = node[ term.value ]
            }

            if (term.type === "string") {
                termValue = term.value
            }

            return termValue
        })
    }

    return collection.map((node: any, i: number) => ({
        entityData: node,
        entityType: type,
        iconSrc,
        id: i + type,
        name: node.name ? node.name : type,
        terms: generateTerms(rawTerms, node),
    }))
}

const generateDemoMatches = () => {
    return {
        dynamo: {
            data: [
                ...generateEntities(organizations, "organization", organizationIconDynamo, [
                    { type: "prop", value: "name" },
                ]),
                ...generateEntities(individuals, "individual", individualIconDynamo, [
                    { type: "prop", value: "name" },
                ]),
            ],
            schema: {

            },
        },

        quick_books: {
            data: [
                ...generateEntities(invoice, "invoice", invoiceIcon, [
                    { type: "prop", value: "number" },
                ]),

                ...generateEntities(organizations, "organization", organizationIconDynamo, [
                    { type: "prop", value: "bankAccount" },
                ]),

                ...generateEntities(purchaseOrders, "purchaseOrder", purchaseOrderIconQuickBooks, [
                    { type: "prop", value: "number" },
                ]),

                ...generateEntities(billsOfLading, "billOfLading", billOfLadingIconQuickBooks, [
                    { type: "prop", value: "billOfLadingNumber" },
                ]),

                ...generateEntities(individuals, "contractNumber", contractIconQuickBooks, [
                    { type: "prop", value: "contractNumber" },
                ]),
                ...generateEntities(leaveRequest, "leaveRequest", billOfLadingIconQuickBooks, [
                    { type: "prop", value: "contractNumber" },
                ]),

            ],
            schema: {

            },
        },

        bulgarian_law: {
            data: [
                ...generateEntities(law, "law", invoiceIcon, [
                    { type: "prop", value: "term" },
                ]),
            ],
            schema: {

            },
        },

        us_law: {
            data: [],
        },

        test: {
            data: [
                ...generateEntities(organizations, "organization", testEntityIcon, [
                    { type: "string", value: "javascript" },
                    { type: "string", value: "alone" },
                    { type: "prop", value: "name" },
                ]),

                ...generateEntities(individuals, "individual", testEntityIcon, [
                    { type: "string", value: "javascript" },
                    { type: "string", value: "alone" },
                    { type: "prop", value: "name" },
                ]),
            ],
        },

        table: {
            data: [
                {
                    name: "Table",
                    tabledata: [],
                },
            ],
        },
    }
}

export default generateDemoMatches
