import { IHostCard } from "../../../host/types"
import { IIndividualData } from "../../../service/data/demo_data"
import { ICardAction } from "../../app/card/components/CardActions"
import { ICardHeader } from "../../app/card/redux/types"
import CardFactory, { IContentItem } from "./CardFactory"

class IndividualCard extends CardFactory<IIndividualData> {

    constructor(card: IHostCard<IIndividualData>, appId: string) {
        super(card, appId)

        const header: ICardHeader = {
            avatar: {
                iconSrc: card.iconSrc,
            },
            color: this.color,
            path: "",
            subheader: card.entityData.role,
            title: card.entityData.name,
        }

        const content: Array<IContentItem<IIndividualData>> = [
            {
                compType: "industry",
                label: "Industry",
                pos: 1,
                type: "industry",
            },
            {
                compType: "website",
                label: "Website",
                pos: 2,
                type: "personalWebsite",
            },
            {
                compType: "phone",
                label: "Phone",
                pos: 3,
                type: "phone",
            },
            {
                compType: "address",
                label: "Office Address",
                pos: 4,
                type: "officeAddress",
            },
            {
                compType: "default",
                label: "Organization",
                pos: 5,
                type: "organization",
            },
            {
                compType: "default",
                pos: 6,
                type: "role",
                label: "Role",
            },
            {
                compType: "default",
                pos: 7,
                type: "department",
                label: "Department",
            },
            {
                compType: "default",
                pos: 8,
                type: "email",
                label: "Email",
            },
            {
                compType: "default",
                pos: 9,
                type: "university",
                label: "University",
            },
            {
                compType: "default",
                pos: 10,
                type: "contractNumber",
                label: "Contract number",
            },


        ]

        const actions: ICardAction[] = [
            {
                handler: "default",
                icon: "phoneLink",
                label: "dial-up",
            },
        ]

        this.setCardProps([ "industry", "personalWebsite", "university", "organization" ])
        this.generateHeader(header)
        this.generateContent(content)
        this.setCardActions(actions)
    }
}

export default IndividualCard
