import {
    DISABLE_UI,
    DisableUi,
    ENABLE_UI,
    HighlightActions,
    REMOVE_HIGHLIGHTS,
    SET_HIGHLIGHTS,
} from "./types"

export const highlightsReducer = (
    state: boolean = false,
    action: HighlightActions,
) => {
    switch (action.type) {
        case SET_HIGHLIGHTS:
            return true

        case REMOVE_HIGHLIGHTS:
            return false

        default:
            return state
    }
}

export const disableUiReducer = (state: boolean = false, action: DisableUi) => {
    switch (action.type) {
        case DISABLE_UI:
            return true

        case ENABLE_UI:
            return false

        default:
            return state
    }
}
