import { CardHeader as MUICardHeader, IconButton } from "@material-ui/core"
import { MoreVert } from "@material-ui/icons"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { ICardHeader } from "../redux/types"
import Avatar from "./Avatar"

const SCardHeader = styled((props) => <MUICardHeader { ...props } />)`
   && {
       padding: 10px 10px 5px 10px;
   }

   && .MuiCardHeader-subheader {
       text-transform: capitalize;
   }
`

const CardHeader: React.FC<ICardHeader> = (props) => {
    const { title, subheader, avatar, path = "", color } = props
    return (
        <SCardHeader
            avatar={ // TODO should make default look for not specified type of card
                avatar ? <Avatar { ...avatar } color={ color } /> : <Avatar contact color={ color } /> }
            action={
                <IconButton to={ path } component={ Link }>
                    <MoreVert></MoreVert>
                </IconButton>
            }
            title={ title }
            subheader={ subheader }
        >

        </SCardHeader>
    )
}

export default CardHeader
