import { IHostCard } from "../../../host/types"
import { IInvoiceData } from "../../../service/data/demo_data"
import { ICardAction } from "../../app/card/components/CardActions"
import { ICardContentItem, ICardHeader } from "../../app/card/redux/types"
import CardFactory, { IContentItem } from "./CardFactory"

class InvoiceCard extends CardFactory<IInvoiceData> {
    constructor(card: IHostCard<IInvoiceData>, appId: string) {
        super(card, appId)
        const header: ICardHeader = {
            avatar: {
                iconSrc: card.iconSrc,
            },
            color: card.color,
            subheader: card.entityData.number.toString(),
            title: "Invoice",
        }

        const content: Array<IContentItem<IInvoiceData>> = [
            {
                compType: "default",
                label: "Recipient Name",
                pos: 1,
                type: "recipientName",
            },
            {
                compType: "default",
                label: "Supplier Name",
                pos: 2,
                type: "supplierName",
            },
            {
                compType: "default",
                label: "Due Date",
                pos: 3,
                type: "dueDate",
            },
            {
                compType: "default",
                label: "Issue Date",
                pos: 4,
                type: "issueDate",
            },
            {
                compType: "default",
                label: "Total",
                pos: 5,
                type: "total",
            },
            {
                compType: "default",
                label: "Recipient Vatin",
                pos: 6,
                type: "recipientVatin",
            },
            {
                compType: "default",
                label: "Supplier Vatin",
                pos: 7,
                type: "supplierVatin",
            },
        ]

        const actions: ICardAction[] = [
            {
                handler: "default",
                icon: "print",
                label: "print",
            },
        ]

        this.setCardActions(actions)
        this.generateHeader(header)
        this.generateContent(content)
        this.setCardProps([ "number", "supplierName", "total" ])
    }
}

export default InvoiceCard
