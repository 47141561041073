import { Dispatch } from "redux"
import ConnectorDescriptorList from "../../../host/descriptor_list"
import {
    ADD_CONNECTOR,
    IConnectorModel,
    IConnectorsActionTypes,
    REMOVE_CONNECTOR,
    SET_CONNECTORS_LIST,
} from "./types"

export const AddConnector = (connector: IConnectorModel): IConnectorsActionTypes => ({
    connector,
    type: ADD_CONNECTOR,
})

export const RemoveConnector = (id: string): IConnectorsActionTypes => ({
    id,
    type: REMOVE_CONNECTOR,
})

export const SetConnectorsList = (list: IConnectorModel[]): IConnectorsActionTypes => ({
    list,
    type: SET_CONNECTORS_LIST,
})

// redux thunk allows us to dispatch functions / this way we can handle async actions
export const fetchConnectorDescriptors = () => {
    return async (dispatch: Dispatch) => {
        const descriptors = await ConnectorDescriptorList.getDescriptors()
        const descriptorsToInstall = descriptors.map((desc) => {
            return {
                color: desc.color,
                description: desc.description,
                iconSrc: desc.iconSrc,
                id: desc.id,
                name: desc.name,
                shortDescription: desc.shortDescription,
            }
        })
        dispatch(SetConnectorsList(descriptorsToInstall))
    }
}
