import idGenerator from "../utilities/id_generator"

interface IDomSubscribtion {
    id: number
    node: HTMLElement
    eventHandler: any
    type: string
}

class Events {
    private static map: { [ key: string ]: IDomSubscribtion } = {}
    private static eventId: number = 0

    public addEvent(node: HTMLElement, type: string, eventHandler: any) {
        const token = idGenerator()
        if (!Events.map[ token ]) {
            Events.map[ token ] = {
                eventHandler,
                id: ++Events.eventId,
                node,
                type,
            }
        } else {
            throw new Error("Event with the same id already exists")
        }


        node.addEventListener(type, eventHandler)
        return token
    }

    public removeEvent(token: string) {
        const eventListener = Events.map[ token ]

        if (eventListener) {
            const { node, eventHandler, type } = eventListener
            node.removeEventListener(type, eventHandler)
            delete Events.map[ token ]
        } else {
            throw new Error("Event listener with this id doesn't exist.")
        }
    }

    public removeAllEvents() {
        for (const prop of Object.keys(Events.map)) {
            const eventListener = Events.map[ prop ]

            if (eventListener) {
                const { node, eventHandler, type } = eventListener

                node.removeEventListener(type, eventHandler)
                delete Events.map[ prop ]
            }

        }
    }

}

export default Events
