import styled, { css } from "styled-components"


export const SCards = styled.div`
    position: relative;

    height: 100%;
    overflow: hidden;
`

export const SCard = styled.div`
    border: 1px solid;
    border-left-width: 3px;
    border-right-width: 3px;
    border-color: ${(props) => props.color};

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    position: relative;
/*
    padding:10px 10px 10px 50px;
    margin: 10px 0; */
    overflow: hidden;
`

export const AppBox = styled.div`
    height: calc(100% - 70px);
`

export const CardsContainer = styled.div`
    height: calc(100% - 60px);
    overflow-y: auto;

    padding:0 10px 10px 10px;

    @media screen and (max-width: 1024px) {
        padding: 5px 5px 64px 5px;
    }
`

export const SCardHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    width: 40px;
`

export const SCardIcon = styled.img`
    object-fit: cover;

    height: 30px;
    width: 30px;
`

export const SCardIconWrapper = styled.div`
    color: ${(props) => props.color};

    display: flex;
    align-items: center;
    justify-content: center;
`

export const SCardTitle = styled.div`
    color: #444444;
    font-size: 20px;

    text-transform: capitalize;
`

export const SCardSubtitle = styled.div`
    display: flex;
    align-items: center;

    margin: 5px 0;

    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
`

export const SCardLabel = styled.div`
    color: #444;
    font-size: 14px;

    margin: 5px 0;
`

export const SPhoneNumber = styled.div`
    margin-left: 10px;
`

export const SCardBody = styled.div`

`

export const SCardOptionsBtn = styled.button`

`
export const SCardViewBtn = styled.div`
    position: absolute;
    top: calc(50% - 15px);
    right: 2px;
`

/* ----------------------------------------------------------
                    CUSTOM MUI TABS
----------------------------------------------------------- */

export const SCustomTabs = css`
    && {
        && .MuiTabs-indicator {
            background-color: #ff3389;
        }
    }
`

export const SCustomTab = css`
    && {
        min-width: 70px;
        &:hover {
            color: #ff8490;
        }
    }

`
/* ----------------------------------------------------------
                    CARD VIEW STYLES
----------------------------------------------------------- */

export const CardViewContainer = styled.div`
    height: 100%;
    width: 100%;
`
/* ----------------------------------------------------------
                        ALL CARDS
----------------------------------------------------------- */

export const SearchWrapper = styled.div`
    background-color: #fff;

    position: absolute;

    margin-bottom: 20px;

    z-index: 999;
    width: 100%;
`

