import { IHostCard } from "../../../host/types"
import { IBillOfLadingData } from "../../../service/data/demo_data"
import { ICardHeader } from "../../app/card/redux/types"
import CardFactory, { IContentItem } from "./CardFactory"

class BillOfLadingCard extends CardFactory<IBillOfLadingData> {
    constructor(
        card: IHostCard<IBillOfLadingData>,
        appId: string,
    ) {
        super(card, appId)

        const header: ICardHeader = {
            avatar: {
                iconSrc: card.iconSrc,
            },
            color: card.color,
            subheader: card.entityData.billOfLadingNumber,
            title: "Bill of lading",
        }

        const content: Array<IContentItem<IBillOfLadingData>> = [
            {
                compType: "default",
                label: "Place Of Delivery",
                pos: 1,
                type: "placeOfDelivery",
            },
            {
                compType: "default",
                label: "Port Of Loading",
                pos: 2,
                type: "portOfLoading",
            },
            {
                compType: "default",
                label: "Booking Number",
                pos: 3,
                type: "bookingNumber",
            },
            {
                compType: "default",
                label: "Carriers Agent",
                pos: 4,
                type: "carriersAgent",
            },
            {
                compType: "default",
                label: "Date Of Issue",
                pos: 5,
                type: "dateOfIssue",
            },
            {
                compType: "default",
                label: "Date On Board",
                pos: 6,
                type: "dateOnBoard",
            },
            {
                compType: "default",
                label: "Freight Charges",
                pos: 7,
                type: "freightCharges",
            },
            {
                compType: "default",
                label: "Gross Weight",
                pos: 7,
                type: "grossWeight",
            },
        ]


        this.setCardProps([ "placeOfDelivery", "grossWeight" ])
        this.generateHeader(header)
        this.generateContent(content)
    }
}

export default BillOfLadingCard
