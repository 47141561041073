export const SET_DOCUMENT_TYPE = "SET_DOCUMENT_TYPE"
export const OPEN_NEW_DOCUMENT = "OPEN_NEW_DOCUMENT"
export const OPEN_APP = "OPEN_APP"
export const CLOSE_APP = "CLOSE_APP"
export const SET_DOCUMENT_NAME = "SET_DOCUMENT_NAME"
export const SET_DOCUMENT_URL = "SET_DOCUMENT_URL"

interface ISetDocumentType {
    type: typeof SET_DOCUMENT_TYPE
    documentType: string
}

interface ISetDocumentName {
    type: typeof SET_DOCUMENT_NAME
    documentName: string
}

interface ISetDocumentUlr {
    type: typeof SET_DOCUMENT_URL
    url: string
}

export type DocumentActions = ISetDocumentType | ISetDocumentName | ISetDocumentUlr

interface IOpenApp {
    type: typeof OPEN_APP
}

interface ICloseApp {
    type: typeof CLOSE_APP
}

export type OpenCloseApp = IOpenApp | ICloseApp

export type IOpenNewDocument = (type: string, source: string) => void

export interface IDocumentState {
    type: string
    name: string
    url: string
}
