import React from "react"
import styled from "styled-components"

interface IOwnProps {
    title: string
}

const SStoreList = styled.ul`
    margin-bottom: 30px;
`

const ListTitle = styled.li`
    font-size: 12px;
    color: #666666;

    padding: 10px 0;
`

const StoreList: React.FC<IOwnProps> = (props) => {
    const { children, title } = props
    return (
        <SStoreList>
            <ListTitle>{ title }</ListTitle>
            { children }
        </SStoreList>
    )
}

export default StoreList
