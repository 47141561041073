import highlight from "./highlight"
import Events from "./events"

const removeHighlights = (appId) => {
    // removes all events attached
    // removes highlights based on current page and pages that have been already rendered

    // hacky AF
    const events = new Events()
    const pagesCount = window.PDFViewerApplication && window.PDFViewerApplication.pagesCount
    // const currPage = window.PDFViewerApplication && window.PDFViewerApplication.page
    events.removeAllEvents()
    for (let page = 1; page <= pagesCount; page++) {
        let page_index = page - 1
        let pageView = window.PDFViewerApplication.pdfViewer.getPageView(page_index)
        if (!pageView.textLayer) {
            break
        }
        highlight.remove_tags(page)
    }

}

export default removeHighlights