import { IHostCard } from "../../../host/types"
import { IIndividualData, ILawData } from "../../../service/data/demo_data"
import { ICardHeader } from "../../app/card/redux/types"
import CardFactory, { IContentItem } from "./CardFactory"

class LawCard extends CardFactory<ILawData> {

    constructor(card: IHostCard<ILawData>, appId: string) {
        super(card, appId)

        const header: ICardHeader = {
            avatar: {
                iconSrc: card.iconSrc,
            },
            color: this.color,
            path: "",
            subheader: card.entityData.lastUpdate,
            title: card.entityData.name,
        }

        const content: Array<IContentItem<ILawData>> = [
            {
                compType: "default",
                pos: 1,
                type: "source",
                label: "Източник:",
            },
            {
                compType: "default",
                pos: 2,
                type: "title",
            },
            {
                compType: "default",
                pos: 3,
                type: "paragraph_1",
            },
            {
                compType: "default",
                pos: 4,
                type: "paragraph_2",
            },
            {
                compType: "default",
                pos: 5,
                type: "paragraph_3",
            },
        ]

        // this.setCardProps([ "industry", "personalWebsite", "university", "organization" ])
        this.generateHeader(header)
        this.generateContent(content)
    }
}

export default LawCard
