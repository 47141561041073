import { IHostCard } from "../../../host/types"
import { ITableData } from "../../../service/data/demo_data"
import { ICardHeader } from "../../app/card/redux/types"
import CardFactory, { IContentItem } from "./CardFactory"

class TableCard extends CardFactory<ITableData> {

    constructor(card: IHostCard<ITableData>, appId: string) {
        super(card, appId)

        const header: ICardHeader = {
            avatar: {
                iconSrc: card.iconSrc,
            },
            color: card.color,
            path: "",
            title: card.name,
        }

        const content: Array<IContentItem<ITableData>> = card.entityData.tableData.map((item, i) => ({
            compType: "row",
            pos: i,
            type: "tableData",
        }))

        this.path = `/table/${appId}/${card.id}`
        this.actions = [
            {
                btnSize: "small",
                handler: "default",
                icon: "export",
                label: "Export to Dynamo",
            },
            {
                btnSize: "small",
                handler: "table",
                icon: "table",
                label: "Export to Excel",
            },
        ]

        this.generateHeader(header)
        this.generateContent(content)
        this.setCardProps([])
    }



}

export default TableCard
