import { Button, IconButton } from "@material-ui/core"
import { AddBox } from "@material-ui/icons"
import React, { useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { AppState } from "../../redux/root"
import { IApp } from "../app_store/redux/types"
import PanelItem from "./PanelItem"
import { PanelBadge, SAddBtnWrapper, SAllBtn, SCPanel } from "./styles"
import { ICard } from "../app/card/redux/types"
import { DataTypes } from "../../service/data/demo_data"

interface ICPanelProps {
    installed: IApp[]
    disabled: boolean
    cards: ICard<DataTypes>[]
}

const CPAllButton = styled(({ ...props }) => <Button { ...props } />)`
    ${SAllBtn}
`

const MapPropsToState = (state: AppState) => ({
    disabled: state.uiDisabled,
    installed: state.installed,
    cards: state.cards,
})

const CPanel: React.FC<ICPanelProps> = (props) => {
    const { installed, disabled, cards } = props
    const badgeColor = "#777"
    const totalCards = cards.length


    return (
        <SCPanel>

            <CPAllButton disabled={ totalCards === 0 || disabled } component={ Link } to="/card/all">
                All
                { totalCards !== 0 && <PanelBadge color={ badgeColor }>{ !disabled && totalCards }</PanelBadge> }
            </CPAllButton>

            { props.children }

            { installed.map((app: IApp, i: number) =>

                <PanelItem
                    key={ i }
                    title={ app.connectorName }
                    path={ `/app/${app.id}` }
                    iconSrc={ app.connectorIcon }
                    count={ cards.filter(card => card.appId === app.id).length }
                    disabled={ disabled }
                />,
            ) }

            <SAddBtnWrapper>
                <IconButton to="/store" component={ Link } disabled={ disabled }>
                    <AddBox fontSize="large" height={ 40 }></AddBox>
                </IconButton>
            </SAddBtnWrapper>

        </SCPanel>
    )
}

export default connect(MapPropsToState)(CPanel)
