import { CLOSE_APP, DocumentActions, IDocumentState, OPEN_APP, OpenCloseApp, SET_DOCUMENT_NAME, SET_DOCUMENT_TYPE, SET_DOCUMENT_URL } from "./types"

const initialState: IDocumentState = {
    name: "Moris Venture",
    type: "pdf",
    url: "/pdfs/moris_venture.pdf",
}

export const documentReducer = (state: IDocumentState = initialState, action: DocumentActions) => {
    switch (action.type) {
        case SET_DOCUMENT_TYPE:
            return Object.assign({}, state, { type: action.documentType })

        case SET_DOCUMENT_NAME:
            return Object.assign({}, state, { name: action.documentName })

        case SET_DOCUMENT_URL:
            return Object.assign({}, state, { url: action.url })

        default:
            return state
    }
}

export const appIsOpenReducer = (state: boolean = false, action: OpenCloseApp) => {
    switch (action.type) {
        case OPEN_APP:
            return true

        case CLOSE_APP:
            return false

        default:
            return state
    }
}
