import { createMemoryHistory } from "history"
import React, { useEffect } from "react"
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux"
import { Router } from "react-router"
import { LastLocationProvider } from "react-router-last-location"
import { AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"
import styled from "styled-components"
import GlobalStyles from "./GlobalStyles"
import setOptions from "./pdf.js/setOptions"
import { AppState } from "./redux/root"
import { resetAll } from "./ui_app/app_store/redux/actions"
import DocsMenu from "./ui_app/document/DocsMenu"
import { OpenApp, OpenNewDocument } from "./ui_app/document/redux/actions"
import { IDocumentState } from "./ui_app/document/redux/types"
import LeftPanel from "./ui_app/LeftPanel"
import ensure from "./utilities/ensure"

interface IStateProps {
  appIsOpen: boolean
  doc: IDocumentState
}

interface IDispatchProps {
  openDoc: (type: string, url: string, name: string) => void
  reset: (docName: string) => void
  openApp: () => void
}

type Props = IStateProps & IDispatchProps

const SApp = styled.div<{ isOpen: boolean }>`
    position: fixed;
    top: 0;
    right: ${(props) => props.isOpen ? "0px" : "-480px"};
    width: 480px;

    transition: right 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

    @media screen and (max-width: 480px) {
      width: 100%;
    }

    z-index: 99999;
`
// declare history in separate module to be able to import it from everywhere
const history = createMemoryHistory()

const mapStateToProps: MapStateToProps<IStateProps, {}, AppState> = (state) => ({
  appIsOpen: state.appIsOpen,
  doc: state.document,
})

// tslint:disable-next-line: max-line-length
const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchProps => ({
  openApp: () => dispatch(OpenApp()),
  openDoc: (type: string, url: string, name: string) => dispatch(OpenNewDocument(type, url, name)),
  reset: (docName: string) => dispatch(resetAll(docName)),
})

const App: React.FC<Props> = (props) => {
  const { appIsOpen, doc, openDoc, reset, openApp } = props
  const { name, type, url } = doc

  useEffect(() => {
    window.addEventListener("DOMContentLoaded", () => {
      setOptions({ defaultUrl: url })
    })
    // reset(name)
  }, [])

  useEffect(() => {
    const { innerWidth } = window
    if (innerWidth > 1024) {
      document.body.style.paddingRight = "480px"
    }

  }, [ appIsOpen ])

  const highlightClickHandler = (ev: MouseEvent) => {
    const target = ev.target
    if (target instanceof HTMLElement && target.tagName === "CUSTOM-HIGHLIGHT") {
      const { id, reactAppId } = target.dataset
      const ids = ensure<string>(id, "The id is undefined.").split(",")
      if (ids.length > 1) {
        history.push({
          pathname: `/app/${reactAppId}`,
          search: `?id=${ids}`,
          state: {
            dontRender: true,
          },
        })
      } else {
        history.push({
          pathname: `/card/${reactAppId}/${id}`,
        })
      }
      // check the window size
      // open the app
      if (window.innerWidth < 1024) {
        openApp()
      }
    }
  }

  useEffect(() => {
    document.body.addEventListener("click", highlightClickHandler)

    return () => {
      document.body.removeEventListener("click", highlightClickHandler)
    }
  }, [])


  return (
    <>
      <Router history={ history }>
        <LastLocationProvider>
          <GlobalStyles />
          <SApp isOpen={ appIsOpen }>
            <LeftPanel />
          </SApp>
          <DocsMenu />
        </LastLocationProvider>
      </Router>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
