import { Button, Card, CardActions, CardContent, IconButton } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import React from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"

interface INotificationProps {
    message?: string
    closeHandler: () => void
}

const Overlay = styled.div`
    background-color: rgba(0,0,0,0.8);

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 99999999;
`

const NCard = styled(({ ...props }) => <Card { ...props } />)`
    && {
        max-height: 95vw;
        min-width: 200px;
        max-width: 95vw;
    }
`

type Props = INotificationProps

const Notification: React.FC<Props> = (props) => {
    const { children, closeHandler } = props
    const domNode = document.getElementById("c-modal") || document.body

    return ReactDOM.createPortal(
        <Overlay>
            <NCard>

                <CardActions>
                    <IconButton onClick={ closeHandler }>
                        <Close />
                    </IconButton>
                </CardActions>

                <CardContent>
                    { children }
                </CardContent>


            </NCard>
        </Overlay>,
        domNode,
    )

}

export default Notification
