import { Button, Zoom } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { PanelBadge, PanelIcon, PanelItemTitle, SButton } from "./styles"
import { CSSTransition } from "react-transition-group"

interface IOwnProps {
    iconSrc: string
    path: string
    count: number
    badgeColor?: string
    title: string
    disabled: boolean
}

const CPButton = styled(({ ...props }) => <Button { ...props } />)`
   ${SButton}
`

const SCPanelItem = styled.div`
    position: relative;
    line-height: 1;

    width: 100%;
`

type Props = IOwnProps

const PanelItem: React.FC<Props> = (props) => {
    const { iconSrc, path, count, badgeColor = "#777", title, disabled } = props
    const transitionTime = 250
    const [ inProps, setInProps ] = useState(false)
    useEffect(() => setInProps(true), [])

    return (
        <Zoom timeout={ transitionTime } in={ inProps }>
            <SCPanelItem>
                <CPButton
                    disabled={ disabled }
                    to={ path }
                    component={ Link }>
                    <PanelIcon src={ iconSrc } disabled={ disabled } />
                    { count > 0 && <PanelBadge color={ badgeColor }>{ !disabled && count }</PanelBadge> }
                    <PanelItemTitle>{ title }</PanelItemTitle>
                </CPButton>
            </SCPanelItem>
        </Zoom>

    )
}

export default PanelItem
