import { CardContent as MUICardContent } from "@material-ui/core"
import React from "react"
import styled from "styled-components"

const SCardContent = styled((props) => <MUICardContent { ...props } />)`
   && {
        padding: 0 16px;
   }
`

const CardContent: React.FC = (props) => {
    const { children } = props
    return (
        <SCardContent>
            { children }
        </SCardContent>
    )
}

export default CardContent
