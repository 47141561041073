import BillOfLadingCard from "./card_factories/BillOfLadingCard"
import CompanyCard from "./card_factories/CompanyCard"
import ContactCard from "./card_factories/ContactCard"
import ContractCard from "./card_factories/ContractCard"
import IndividualCard from "./card_factories/IndividualCard"
import InvoiceCard from "./card_factories/InvoiceCard"
import LawCard from "./card_factories/LawCard"
import LeaveRequestCard from "./card_factories/LeaveRequestCard"
import PurchaseOrderCard from "./card_factories/PurchaseOrderCard"
import RoleCard from "./card_factories/RoleCard"
import TableCard from "./card_factories/TableCard"

export type CardFactories =
    typeof ContactCard |
    typeof CompanyCard |
    typeof IndividualCard |
    typeof BillOfLadingCard |
    typeof InvoiceCard |
    typeof PurchaseOrderCard |
    typeof TableCard |
    typeof LawCard |
    typeof LeaveRequestCard

// tslint:disable-next-line: max-line-length
export type CardTypes = BillOfLadingCard | CompanyCard | ContactCard | IndividualCard | InvoiceCard | PurchaseOrderCard | TableCard | LeaveRequestCard | LawCard

interface IFactories {
    [ key: string ]: CardFactories
}

const factories: IFactories = {
    billOfLading: BillOfLadingCard,
    contact: ContactCard,
    contractNumber: ContractCard,
    individual: IndividualCard,
    invoice: InvoiceCard,
    law: LawCard,
    leaveRequest: LeaveRequestCard,
    organization: CompanyCard,
    purchaseOrder: PurchaseOrderCard,
    roleCode: RoleCard,
    table: TableCard,
}

export default factories
