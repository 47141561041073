import { DataTypes } from "../../../../service/data/demo_data"
import {
    ADD_CARD,
    ADD_CARDS,
    ICard,
    ICardActions,
    ICardState,
    REMOVE_ALL_CARDS,
    REMOVE_CARD,
    REMOVE_CARDS_BY_APP_ID,
} from "./types"

type IInitialCardState = Array<ICard<DataTypes>>

export const cardsReducer = (state: IInitialCardState = [], action: ICardActions): ICardState => {
    switch (action.type) {
        case ADD_CARD:
            return [ ...state, action.card ]

        case REMOVE_CARD:
            return state.filter((card) => !(card.appId === action.appId && card.id === action.cardId))

        case ADD_CARDS:
            return [ ...state, ...action.cards ]

        case REMOVE_CARDS_BY_APP_ID:
            return state.filter((card) => card.appId !== action.appId)

        case REMOVE_ALL_CARDS:
            return []

        default:
            return state
    }
}

