import { IconButton, InputAdornment, TextField } from "@material-ui/core"
import { DragHandle, Search, ViewStream } from "@material-ui/icons"
import React, { ChangeEvent, useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import { RouteComponentProps } from "react-router"
import { useLastLocation } from "react-router-last-location"
import { AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"
import ViewHeader from "../../components/ViewHeader"
import { disableUi, enableUi, initiateRemoveHighlights, setHighlights } from "../../redux/actions"
import { AppState } from "../../redux/root"
import { DataTypes } from "../../service/data/demo_data"
import { IApp } from "../app_store/redux/types"
import Card from "./card/Card"
import CardCompact from "./card/CardCompact"
import { ICard } from "./card/redux/types"
import AppActions from "./components/AppActions"
import { AppBox, CardsContainer, SCards } from "./styles"

interface IHashedInstalledApp {
    [ key: string ]: IApp
}

interface IRouteParams {
    appId: string
}

interface IOwnProps extends RouteComponentProps<IRouteParams> { }

interface IStateProps {
    app: IApp
    cards: Array<ICard<DataTypes>>
    highlights: boolean
    disabled: boolean
}

interface IDispatchProps {
    setHiLights: (connectorId: string, appId: string) => void
    removeHiLights: () => void
    disblUi: () => void
    enblUi: () => void
}



const MapStateToProps = (state: AppState, ownProps: IOwnProps): IStateProps => {
    const appId = ownProps.match.params.appId
    const hashedInstalledApps = state.installed
        .reduce<IHashedInstalledApp>((init: IHashedInstalledApp, app: IApp) => {
            const result = { ...init }
            result[ app.id ] = app
            return result
        }, {})

    return {
        app: hashedInstalledApps[ appId ],
        cards: state.cards.filter((card) => card.appId === appId),
        disabled: state.uiDisabled,
        highlights: state.highlights,
    }
}

const MapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchProps => ({
    disblUi: () => dispatch(disableUi()),
    enblUi: () => dispatch(enableUi()),
    removeHiLights: () => dispatch(initiateRemoveHighlights()),
    setHiLights: (
        connectorId: string, appId: string,
    ) => dispatch(setHighlights(connectorId, appId)),
})

type Props = IOwnProps & IStateProps & IDispatchProps

const AppView: React.FC<Props> = (props) => {
    const {
        match,
        history,
        location,
        cards,
        app,
        setHiLights,
        removeHiLights,
        enblUi,
        disblUi,
        highlights,
        disabled,
    } = props
    const { appId } = match.params
    const { connectorName, connectorId } = app
    // hooks
    const [ cardsList, changeCards ] = useState<Array<ICard<DataTypes>>>(cards)
    const [ compact, setCompact ] = useState(true)
    const [ searchValue, setSearchValue ] = useState("")

    // if we render particular cards we speicify it in the route with query params
    const queryParams = new URLSearchParams(location.search)
    const idParams = queryParams.get("id")
    const lastLocation = useLastLocation()

    const renderCards = () => (
        cardsList.map((card: ICard<DataTypes>, i: number) =>
            <Card { ...card } key={ i } />,
        )
    )

    const renderCompactCards = () => {
        return cardsList.map((card: ICard<DataTypes>, i: number) =>
            <CardCompact { ...card } key={ i } />,
        )
    }

    const hashedCards = useMemo(() => cards.reduce<{ [ key: string ]: ICard<DataTypes> }>((init, card) => {
        init[ card.id ] = card
        return init
    }, {}), [ cards ])

    useEffect(() => {
        changeCards(cards)
    }, [ cards ])

    useEffect(() => {
        // the filter can happen in redux tho\
        if (idParams) {
            const ids = idParams.split(",")
            const filteredCards = ids.map((cardId: string) => hashedCards[ cardId ])
            changeCards(filteredCards)
        }
    }, [ idParams ])

    useEffect(() => {
        // console.log(searchValue)
    }, [ searchValue ])

    useEffect(() => {
        // you can pass state prop with the pathname to the Link comp or history.push
        const dontRender = history.location.state && history.location.state.dontRender
        // where we are comming from ?
        const locationsRegex = new RegExp(`(card/${appId}|app/${appId})`, "g")
        const lastLoc = lastLocation && lastLocation.pathname.match(locationsRegex)

        // checks paths for witch we dont want to remove the highlights
        // console.log(lastLoc, highlights)
        const load = async () => {
            if (!(dontRender || (lastLoc && highlights))) {
                disblUi()
                changeCards(cards.filter((card) => card.appId === appId))
                await setHiLights(connectorId, appId)
                enblUi()
            }
        }
        load()

        return () => {
            const goingTo = history.location.pathname
            const goingToUrlRegex = new RegExp(`(card/${appId}|app/${appId})`, "g")
            const goingToMatches = goingTo.match(goingToUrlRegex)

            if (!goingToMatches) {
                removeHiLights()
            }
        }
    }, [
        appId,
        connectorId,
        history.location.pathname,
        history.location.state,
        setHiLights,
        removeHiLights,
        disblUi,
        enblUi,
        // TODO find a way to fix the problem with missing dependencies --> "react-hooks/exhaustive-deps"
    ])

    return (
        <SCards>
            <ViewHeader
                title={ connectorName }
                btn="back"
                disabled={ disabled }
                btnClickHandler={ () => history.push("/") }
            />

            <AppBox>

                <AppActions>
                    <div style={ { marginRight: "10px" } }>
                        <IconButton
                            onClick={ () => compact ? setCompact(false) : setCompact(true) }
                            aria-label="set compact view of the cards"
                            size="small">
                            { compact ? <ViewStream /> : <DragHandle /> }
                        </IconButton>
                    </div>
                    <TextField
                        value={ searchValue }
                        onChange={ (ev: ChangeEvent<HTMLTextAreaElement>) => setSearchValue(ev.target.value) }
                        id="standard-dense"
                        placeholder="Search..."
                        margin="dense"
                        fullWidth={ true }

                        InputProps={ {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search htmlColor="#c1c1c1" />
                                </InputAdornment>
                            ),
                        } }
                    />
                </AppActions>

                <CardsContainer>
                    { compact ? renderCompactCards() : renderCards() }
                </CardsContainer>

            </AppBox>

        </SCards>
    )
}

export default connect(MapStateToProps, MapDispatchToProps)(AppView)
