import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { throttle } from "lodash"

const SView = styled.div<{ newScreenHeight: number }>`
    position: relative;

    height: ${props => props.newScreenHeight}px;
    width: calc(100% - 70px);

    @media screen and (max-width: 1024px) {
        width: calc(100% - 60px);
    }
`

const View: React.FC<{}> = (props) => {
    const [ screenHeight, setScreenHeight ] = useState(window.innerHeight)

    const resizeHandler = () => {
        console.log('resized');
        setScreenHeight(window.innerHeight)
    }

    const throttledHandler = throttle(resizeHandler, 200, { trailing: false })

    useEffect(() => {
        window.addEventListener("resize", throttledHandler)
    }, [])

    return (
        <SView newScreenHeight={ screenHeight }>
            { props.children }
        </SView>
    )
}

export default View
