import { combineReducers } from "redux"
import { cardsReducer } from "../ui_app/app/card/redux/reducers"
import { connectorsReducer } from "../ui_app/app/redux/reducers"
import { installationReducer } from "../ui_app/app_store/redux/reducers"
import { appIsOpenReducer, documentReducer } from "../ui_app/document/redux/reducers"
import { disableUiReducer, highlightsReducer } from "./reducers"

const rootReducer = combineReducers({
    appIsOpen: appIsOpenReducer,
    cards: cardsReducer,
    connectors: connectorsReducer,
    document: documentReducer,
    highlights: highlightsReducer,
    installed: installationReducer,
    uiDisabled: disableUiReducer,
})

export type AppState = ReturnType<typeof rootReducer>
export default rootReducer
