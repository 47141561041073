import uniq from "../utilities/unique_values"
// import demoMatches from "./demo_matches.json"
import generateDemoMatches from "./data/demo_data"

const demoMatches = generateDemoMatches()

type IDemoService<T> = (text: string, serviceName: keyof typeof demoMatches) => Promise<T[]>

interface ITerm {
    entityItems: any[]
    originalName: string
    serviceName: string
}

interface IMatchedTerms {
    [ key: string ]: ITerm
}

// typescript throws if you try to access prop with value about
// which typescript is not sure if it's key of the object
const DemoService: IDemoService<ITerm> = (text: string, serviceName: keyof typeof demoMatches) => {
    // demoMatches is a json with the entity data for each connector
    // dynamicaly load the data by service name

    interface IService {
        [ key: string ]: any
    }

    const service: IService = demoMatches[ serviceName ]
    const matchedTerms: IMatchedTerms = {}

    if (serviceName === "table") {
        return Promise.resolve([])
    } else {
        for (const d of service.data) {
            const terms = d.terms
            const filteredTerms = terms.filter((term: string) => term !== "")
            if (filteredTerms.length === 0) {
                continue
            }
            const escapedTerms = filteredTerms.map((term: string) => term.replace(/[.*+?^${}()|[\]\\]/g, "\\$&").trim())
            // const regs = new RegExp(`(\\s\|\^)(${escapedTerms.join("\|")})(\?\!\[а-я\])`, "gmi")
            const regs = new RegExp(`(${escapedTerms.join("\|")})`, "gmi")
            const matched = text.match(regs)
            console.log(text, regs)

            if (matched) {
                // returns unique matches
                // the regex returns results with white space front/back hence the tirm
                const uniqueMatches = uniq(matched.map((e) => e.trim()))
                uniqueMatches.forEach((term: string) => {
                    const entityData = d
                    // if there are multiple entities assosiated with the same term
                    // push the new entity in the entity_items property for the given term
                    // else create new prop
                    if (matchedTerms[ term ]) {
                        matchedTerms[ term ].entityItems.push(entityData)
                    } else {
                        matchedTerms[ term ] = {
                            entityItems: [ entityData ],
                            originalName: term,
                            serviceName,
                        }
                    }
                })
            }
        }

        return Promise.resolve(Object.values(matchedTerms))
    }


}

export default DemoService
