import { IHostCard } from "../../../host/types"
import { IIndividualData, ILeaveRequest } from "../../../service/data/demo_data"
import { ICardHeader } from "../../app/card/redux/types"
import CardFactory, { IContentItem } from "./CardFactory"
import { ICardAction } from "../../app/card/components/CardActions"

class LeaveRequestCard extends CardFactory<ILeaveRequest> {

    constructor(card: IHostCard<ILeaveRequest>, appId: string) {
        super(card, appId)

        const header: ICardHeader = {
            avatar: {
                iconSrc: card.iconSrc,
            },
            color: this.color,
            path: "",
            subheader: card.entityData.number,
            title: "Leave Request",
        }

        const content: Array<IContentItem<ILeaveRequest>> = [
            {
                compType: "default",
                label: "Employee",
                pos: 1,
                type: "employeeName",

            },
        ]

        const actions: ICardAction[] = [
            {
                handler: "default",
                icon: "print",
                label: "print",
            },
        ]

        this.setCardActions(actions)

        // this.setCardProps([ "industry", "personalWebsite", "university", "organization" ])
        this.generateHeader(header)
        this.generateContent(content)
    }
}

export default LeaveRequestCard
