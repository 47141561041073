export const DISABLE_UI = "DISABLE_UI"
export const ENABLE_UI = "ENABLE_UI"

export const SET_HIGHLIGHTS = "SET_HIGHLIGHTS"
export const REMOVE_HIGHLIGHTS = "REMOVE_HIGHLIGHTS"

interface ISetHighlights {
    type: typeof SET_HIGHLIGHTS
}

interface IRemoveHighlights {
    type: typeof REMOVE_HIGHLIGHTS
}

export type HighlightActions = ISetHighlights | IRemoveHighlights

interface IDisableInterface {
    type: typeof DISABLE_UI
}

interface IEnableInterface {
    type: typeof ENABLE_UI
}

export type DisableUi = IDisableInterface | IEnableInterface
