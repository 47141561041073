export interface IApp {
    connectorName: string,
    id: string
    connectorId: string,
    connectorColor: string
    connectorIcon: string
    connectorShortDescription: string
    cardsCount: number
}

export type IAppsState = IApp[]

// we need multiple apps from the same type, each with its own state
// actions "installed apps"
export const INSTALL_APP = "INSTALL_APP"
export const REMOVE_APP = "REMOVE_APP"
export const REMOVE_ALL_APPS = "REMOVE_ALL_APPS"

interface IInstallApp {
    type: typeof INSTALL_APP
    app: IApp
}

interface IRemoveApp {
    type: typeof REMOVE_APP
    id: string
}

interface IRemoveAllApps {
    type: typeof REMOVE_ALL_APPS
}

export type IAppInstallationActions = IInstallApp | IRemoveApp | IRemoveAllApps
