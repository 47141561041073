import { IHostCard } from "../../../host/types"
import { IIndividualData } from "../../../service/data/demo_data"
import { ICardHeader } from "../../app/card/redux/types"
import CardFactory, { IContentItem } from "./CardFactory"

class ContractCard extends CardFactory<IIndividualData> {

    constructor(card: IHostCard<IIndividualData>, appId: string) {
        super(card, appId)

        const header: ICardHeader = {
            avatar: {
                iconSrc: card.iconSrc,
            },
            color: this.color,
            path: "",
            subheader: card.entityData.contractNumber,
            title: "Contract",
        }

        const content: Array<IContentItem<IIndividualData>> = [
            {
                compType: "industry",
                pos: 1,
                type: "industry",
            },
            {
                compType: "website",
                pos: 2,
                type: "personalWebsite",
            },
            {
                compType: "default",
                pos: 3,
                type: "organization",
            },
            {
                compType: "default",
                pos: 4,
                type: "role",
            },
            {
                compType: "default",
                pos: 5,
                type: "department",
            },
            {
                compType: "default",
                pos: 6,
                type: "email",
            },
            {
                compType: "phone",
                pos: 7,
                type: "phone",
            },
            {
                compType: "address",
                pos: 8,
                type: "officeAddress",
            },
        ]

        this.setCardProps([ "industry", "personalWebsite", "university", "organization" ])
        this.generateHeader(header)
        this.generateContent(content)
    }
}

export default ContractCard
