import React from "react"
import styled from "styled-components"

interface IOwnProps { }

type Props = IOwnProps

const Actions = styled.div`
    background-color: #ffffff;

    display: flex;
    align-items: center;
    padding: 6px 28px 10px 10px;

`

const AppActions: React.FC<Props> = (props) => {
    const { children } = props


    return (
        <Actions>
            { children }
        </Actions>
    )
}

export default AppActions
