import { CardActions as MUICardActions, IconButton } from "@material-ui/core"
import { GridOn, ImportExport, Navigation, Phonelink, PhonelinkRing, SvgIconComponent, Print } from "@material-ui/icons"
import React from "react"
import styled from "styled-components"

export interface ICardAction {
    label: string
    btnSize?: "small" | "medium"
    icon: string
    handler: string
}

interface IOwnProps {
    actions: ICardAction[]
    btnSize: "small" | "medium"
}

type Props = IOwnProps

const SCardActions = styled((props) => <MUICardActions { ...props } />)`
   && {
        padding: 5px 10px 10px 10px;
   }
`

const handlers: { [ key: string ]: () => void } = {
    default: () => console.log("default"),
    table: () => window.open("./tablica.xls"),
}

const setAction = (action: ICardAction, key: number, size: "small" | "medium") => {
    const { icon, label, btnSize = size, handler } = action
    const Icons: { [ key: string ]: SvgIconComponent } = {
        export: ImportExport,
        navigation: Navigation,
        phoneLink: PhonelinkRing,
        table: GridOn,
        print: Print,
    }

    const CompIcon = Icons[ icon ]
    if (CompIcon) {
        return (
            <IconButton aria-label={ label } size={ btnSize } onClick={ handlers[ handler ] } key={ key }>
                <CompIcon />
            </IconButton>
        )
    }

    return (
        <IconButton aria-label={ label } size={ btnSize } onClick={ handlers[ handler ] } key={ key }>
            <img src={ icon } />
        </IconButton>
    )
}

const CardActions: React.FC<Props> = (props) => {
    const { actions, btnSize } = props

    return (
        <SCardActions>
            { actions.map((action, i) => setAction(action, i, btnSize)) }
        </SCardActions>
    )
}

export default CardActions
