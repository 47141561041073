import {
    ADD_CARD, ADD_CARDS,
    ICard, ICardActions,
    REMOVE_ALL_CARDS,
    REMOVE_CARD,
    REMOVE_CARDS_BY_APP_ID,
} from "./types"
import { DataTypes } from "../../../../service/data/demo_data"

export const AddCard = (card: ICard<any>): ICardActions => ({
    card,
    type: ADD_CARD,
})

export const RemoveCard = (cardId: string, appId: string) => ({
    appId,
    cardId,
    type: REMOVE_CARD,
})

export const RemoveCardsByAppId = (appId: string) => ({
    appId,
    type: REMOVE_CARDS_BY_APP_ID,
})


export const AddCards = (cards: Array<ICard<DataTypes>>) => ({
    cards,
    type: ADD_CARDS,
})

export const RemoveAllCards = () => ({
    type: REMOVE_ALL_CARDS,
})
