import React from "react"
import { connect } from "react-redux"
import { RouteChildrenProps } from "react-router"
import styled from "styled-components"
import { AppState } from "../redux/root"

interface ISDefaultViewStateProps extends RouteChildrenProps {
    length: number
}

const SDefaultView = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;

    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    text-align: center;

    padding: 20px;


    @media screen and (max-width: 1024px) {
        padding: 10px;
    }

    height: 100%;
`

const MapPropsToState = (state: AppState) => ({
    length: state.installed.length,
})

type IProps = ISDefaultViewStateProps

const DefaultView: React.FC<IProps> = (props) => {
    const { length, history } = props
    return (
        <SDefaultView>
            {
                (length !== 0) ?
                    // tslint:disable-next-line: max-line-length
                    <div>Select one of the installed apps or add new one from the
                    <div onClick={ () => history.push("/store") }>
                            Store
                        </div>
                    </div> :
                    <div>Open the
                        Store
                 to add new application</div>
            }
        </SDefaultView >
    )
}


export default connect(MapPropsToState)(DefaultView)
